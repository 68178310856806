/**
 * 关联唯一 key 的 storage
 */
export default class {
  constructor (key) {
    if (key) {
      this.key = key
    } else {
      console.warn('key is require!')
    }
  }

  key = ''

  // 保持 get 的实时性，所以没有加缓存
  get () {
    try {
      const msgIds = JSON.parse(localStorage.getItem(this.key))

      if (Array.isArray(msgIds)) {
        return msgIds
      }
    } catch (error) {
      console.warn(error)
    }

    return []
  }

  set (value) {
    const list = this.get()
    const index = list.indexOf(value)

    if (index === -1) {
      list.push(value)
      localStorage.setItem(this.key, JSON.stringify(list))
    }
  }

  has (value) {
    const list = this.get()

    return list.indexOf(value) !== -1
  }

  clear () {
    localStorage.removeItem(this.key)
  }

  remove (value) {
    const list = this.get()
    const index = list.indexOf(value)

    if (index !== -1) {
      list.splice(index, 1)
      localStorage.setItem(this.key, JSON.stringify(list))
    }
  }
}
