import Vue from 'vue'
import xss, { whiteList } from 'xss'
import YXTBIZ from 'yxt-biz-pc'

import ImChat from '../store/chat'
import EventBus from '../utils/eventBus'
import { domain } from '../utils/getConfig'
import Announcement from './announcement/index.js'
import Bell from './imBell/index.js'
import Notices from './notices/index.js'
import MsgCenter from './msgCenter/msgCenter.js'
import MsgDetailList from './msgCenter/msgDetailList.js'
import IM from './IM.js'
import sUtils from '../store/utils'

import '../styles/index.scss'

import IMSDK from '../im-sdk/index.js'

// xss
try {
  for (const key in whiteList) {
    Object.hasOwnProperty.call(whiteList, key) && whiteList[key].push('style')
  }
  Vue.prototype.xss = xss
} catch {
  //
}

const version = process.env.VERSION || require('../../package.json').version

const components = [Announcement, Bell, Notices, MsgCenter, MsgDetailList, IM]

const PCIM = {
  chat: ImChat,
  eventBus: EventBus
}

const install = function (Vue, option) {
  // hack 不同格式
  window.im_app_qr = option?.downAppScan || domain?.downAppScan
  IMSDK.prepareOptions({
    imApi: YXTBIZ.Api.create({
      baseURL: window.feConfig.common.IM,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        source: 501 // 参照yxt-biz-pc 写死
      }
    })
  })

  // 判断是否安装
  if (install.installed) return
  sUtils.initLang()
  components.forEach((component) => {
    Vue.component(component.name, component)
  })
  install.installed = true
}

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
  window.ImChat = ImChat
  window.ImEventBus = EventBus
  window.PCIM = PCIM
}

export default {
  version,
  install,
  Announcement,
  Bell,
  IM,
  Notices,
  MsgCenter,
  MsgDetailList
}
