import { mapGetters } from 'vuex'

import { isDingDing, isISV } from '../utils'

export default {
  computed: {
    ...mapGetters(['getIMStatus', 'getOnlineHelpStatus', 'getDownloadAppStatus', 'getAiChatStatus', 'getAiRobotStatus']),
    isISV() {
      return isISV()
    },
    isDingDing() {
      return isDingDing()
    },
    isOpenIM() {
      return this.getIMStatus === 2 || this.isDingDing
    },
    isOpenOnlineHelp() {
      return this.getOnlineHelpStatus === 2
    },
    isOpenDownloadApp() {
      return this.getDownloadAppStatus === 2
    },
    openAiChat() {
      return this.getAiChatStatus === 2
    },
    openAiRobot() {
      return this.getAiRobotStatus === 2
    }
  }
}
