<template>
  <section v-if="isAppDomShow" class="im-notice-center">
    <header class="im-center-header">
      <span class="im-notice-center-title">{{
        $t('h5_IM_default_msg_center')
      }}</span>
      <span
        class="color-gray-8 hover-primary-6 im-notice-list-readall"
        :class="{ 'is-disabled': readDisabled }"
        @click="readAll"
      >
        <yxtf-svg
          class="im-icon-read-all"
          width="20px"
          height="20px"
          icon-class="im/im_read_all"
        />
        {{ $t('pc_IM_bell_set_all_read').d('全部标记已读') }}
      </span>
    </header>
    <section
      v-floading="isLoadingContact"
      yxtf-loading-background="transparent"
      class="im-notice-center-body"
    >
      <div v-if="nodata" style="padding-top: 147px">
        <yxtf-empty :empty-text="$t('pc_IM_bell_no_msg')"></yxtf-empty>
      </div>
      <ul v-else class="im-notice-list">
        <li v-for="(item, key) in list" :key="key" @click="clickHandler(item)">
          <div class="greyWapper">
            <yxtf-badge
              class="im-notice-type"
              :value="item._node_.unread"
              :max="99"
              :hidden="!item._node_.unread"
            >
              <img :src="item._node_.imgUrl" alt="" />
            </yxtf-badge>
            <div class="im-notice-body">
              <div class="im-notice-left">
                <h3 class="im-notice-title">
                  <yxtbiz-user-name :name="item.name" />
                </h3>
                <div class="im-notice-content">{{ item | last }}</div>
              </div>

              <div class="im-notice-topLabel" @click.stop="toTop(item)">
                {{
                  item.stick === 1
                    ? $t('pc_IM_msg_center_un_top').d('取消置顶')
                    : $t('pc_IM_msg_center_top').d('置顶')
                }}
              </div>
              <div class="im-notice-time">
                {{ item._node_.updateTime | formatNoticeCenterTime }}
              </div>
            </div>
            <div v-show="item.stick === 1" class="im-topImg">
              <img
                :src="`${staticBaseUrl}assets/148cf22d/c7ce05ef/totop.svg`"
                alt=""
              />
            </div>
          </div>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IMSDK from '@IM/im-sdk/index'
import { formatNoticeCenterTime } from '@IM/filters/filters'
import {
  changeAllMsgStatus,
  noticeAllRead,
  putContactsTop,
  putContactsUnTop
} from '@IM/service/main.service'
import noticeList from '@IM/conost/notcieList'

function parselatestContent(msg) {
  let latestContent = msg
  if (typeof msg === 'string') latestContent = JSON.parse(msg)
  if (latestContent.ext && typeof latestContent.ext === 'string') {
    latestContent.ext = JSON.parse(latestContent.ext)
  }
  if (
    latestContent.ext.yxtMsgBody &&
    typeof latestContent.ext.yxtMsgBody === 'string'
  ) {
    latestContent.ext.yxtMsgBody = JSON.parse(latestContent.ext.yxtMsgBody)
  }
  return latestContent
}

export default {
  name: 'YxtImMsgCenter',
  filters: {
    last(input) {
      if (!input._node_?.latestContent) return ''
      try {
        const latestContent = parselatestContent(input._node_?.latestContent)
        return latestContent.ext.yxtMsgBody.content[0]?.subject || ''
      } catch {
        return ''
      }
    },
    formatNoticeCenterTime
  },
  data() {
    return {
      staticBaseUrl: window.feConfig.common.staticBaseUrl,
      typeList: [
        'cultivate',
        'examine',
        'investigation',
        'community',
        'announcement',
        'notice',
        'integral',
        'certificate',
        'talent'
      ]
    }
  },

  computed: {
    ...mapGetters(['isAppDomShow', 'getSysContactList', 'isLoadingContact']),
    chatList() {
      return (
        this.typeList.map((value) =>
          noticeList.find((item) => item.type === value)
        ) || []
      )
    },
    chatIdList() {
      return this.chatList.map((item) => item.id)
    },
    nodata() {
      return !this.list.length
    },
    list() {
      return this.getSysContactList.map((item) => {
        return {
          ...item,
          id: item.contacterId,
          name: item.fullName,
          _node_: item
        }
      })
    },

    readDisabled() {
      return !this.list.some((item) => item._node_.unread)
    }
  },
  updated() {
    console.debug('sf', this.$t('h5_IM_default_msg_center'), this.$i18n)
  },
  async mounted() {
    console.log('%c MsgCenter组件 ', 'color: #fff;background: blue;')
    await this.initAppData()
  },

  methods: {
    ...mapActions(['initAppData', 'updateUserList']),
    clickHandler(item) {
      this.$emit('item-click', item)
    },
    async readAll() {
      if (this.readDisabled) return false
      await changeAllMsgStatus()
      await noticeAllRead()
      this.$nextTick(() => {
        IMSDK.awaitOpen().then(this.updateUserList)
      })
    },

    async toTop(item) {
      try {
        if (item.stick === 1) {
          await putContactsUnTop(item.contacterId)
        } else {
          await putContactsTop(item.contacterId)
        }
      } catch (e) {
        console.warn(e)
        this.$message.error(e.message)
      } finally {
        this.updateUserList()
      }
    }
  }
}
</script>
