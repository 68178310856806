<template>
  <div v-if="!loading && (isOpenTools || isDingDing)" class="yxt-IM">
    <Sidebar
      v-show="!isDingDing"
      v-if="isOpenTools"
      :disabled-online-help="disabledOnlineHelp"
    ></Sidebar>
    <Urge v-if="isOpenIM" />
    <ChatModalDialog v-if="isOpenImChat" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import IMSDK, { ImApiUtil } from '@IM/im-sdk/index'
import { TYPES, CHAT_TYPE } from '@IM/conost/chatType'
import MUTATIONS_TYPES from '@IM/store/chatMutationsTypes'
import MSG_STATUS from '@IM/conost/messageStatus'
import isvMinxins from '@IM/mixins'
import { parseMsgPayload } from '@/utils'
import Urge from './urge/Index.vue'
import Sidebar from './sidebar/Index.vue'
// import ChatModalDialog from './chatModalDialog/Index.vue'
import EventBus from '../utils/eventBus'

export default {
  name: 'YxtIm',
  components: {
    Urge,
    Sidebar,
    ChatModalDialog: () =>
      import(
        /* webpackChunkName: "chatModalDialog" */ './chatModalDialog/Index.vue'
      )
  },
  mixins: [isvMinxins],
  provide() {
    return {
      platform: this.platform
    }
  },
  props: {
    platform: {
      type: String,
      default: 'student'
    },
    /**
     * TODO 协助解决线上问题，采用简单方法 透传参数。后期完善方案
     */
    disabledOnlineHelp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      unreadCount: 0
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'IMfetchHistoryMessages', 'selectedContact']),
    isOpenImChat() {
      return this.isOpenIM
    },
    isOpenTools() {
      return this.isOpenIM || this.isOpenOnlineHelp || !this.isISV
    },
    msgList() {
      return this.IMfetchHistoryMessages
    }
  },
  async created() {
    EventBus.$on('IM:getCmdMessage', () => {
      this.updateUserList()
      this.updateUnreadMsg()
    })
    const d = await this.initAppData()
    console.log(d, this.isOpenImChat, this.isOpenTools, this.isOpenIM)
    if (!d) return
    if (d.IMStatus !== 2) {
      console.warn(`IM没有初始化. webState: ${d.IMStatus}`)
    } else {
      console.info(`IM初始化数据获取成功`)
      this.addImListen()
    }
    this.loading = false
  },
  destroyed() {
    EventBus.$off('IM:getCmdMessage')
  },
  methods: {
    ...mapActions(['initAppData', 'updateUserList', 'updateUnreadMsg']),
    async addImListen() {
      IMSDK.awaitOpen().then((conn) => {
        console.debug('im track, conn', conn)
        conn.listen({
          onTextMessage: this.onTextMessage,
          onPictureMessage: this.onPictureMessage, // 可能未使用
          onCmdMessage: this.onCmdMessage,
          onRecallMessage: this.onRecallMessage,
          onReceivedMessage: this.onReceivedMessage,
          onError: this.onError
        })
      })
    },
    /**
     * 处理登录的回调
     */
    async onTextMessage(msg) {
      console.debug('im track, listen->onTextMessage', JSON.stringify(msg))
      let { from, to, type, time, id, ext, data = '' } = msg
      let customExts
      // 分享
      if ([2].indexOf(ext.yxtMsgType) !== -1) {
        customExts = ext.yxtMsgBody ? JSON.parse(ext.yxtMsgBody) : {}

        // 分享图片
      } else if ([12].indexOf(ext.yxtMsgType) !== -1) {
        customExts = ext.yxtMsgBody ? JSON.parse(ext.yxtMsgBody) : {}
        data = customExts.content?.[0]
      } else if ([11].indexOf(ext.yxtMsgType) !== -1) {
        customExts = ext.yxtMsgBody ? JSON.parse(ext.yxtMsgBody) : {}
        data = customExts.content?.[0].subject
      } else if ([10].indexOf(ext.yxtMsgType) !== -1) {
        customExts = ext.yxtMsgBody ? JSON.parse(ext.yxtMsgBody) : {}
        data = customExts.image
      } else if (ext.yxtMsgType !== undefined) {
        customExts = ext.yxtMsgBody ? JSON.parse(ext.yxtMsgBody)?.content : []
      }
      let Msgtype = TYPES[ext?.yxtMsgType] || 'text'
      if (ext?.yxtMsgVoice === 1) {
        Msgtype = 'audio'
        customExts = JSON.parse(ext?.yxtMsgBody) || {}
        customExts.isRead = true
      } else if (ext?.yxtMsgVideo === 1) {
        // 视频信息
        Msgtype = 'video'
        customExts = JSON.parse(ext?.yxtMsgBody) || {}
        customExts.isRead = true
      }

      const bySelf = from === this.userInfo.imUserName

      let message = {
        chatType: type,
        msg: data,
        bySelf,
        from,
        time: Number(time),
        to,
        id,
        status: MSG_STATUS.UNREAD,
        type: Msgtype,
        customExts
      }

      if (window.sdcs_data_interceptor) {
      // 代表开启了通讯录加密
      message = await window.sdcs_data_interceptor(message)
    }

      if (ext.phimRecall) {
        message.phimRecall = true
        message.msg = this.$t('h5_IM_recall_msg')
        message.type = 'groupNotice'
        // const { from, to } = message

        const clone = JSON.parse(JSON.stringify(message))
        // 不知道为什么要对调，对调后撤回信息推送的收不到
        // clone.to = from
        // clone.from = to
        clone.mid = ext.msgId
        this.$store.commit(MUTATIONS_TYPES.DELETE_MESSAGE, clone)
      }
      this.addMessage(message)
      // eslint-disable-next-line eqeqeq
      if (type == CHAT_TYPE.CHAT) {
        IMSDK.ack(message)
      }
      // todo
      if (IMSDK.webIM && message && message.ext && message.ext.msg_extension) {
        IMSDK.webIM.then((webIM) => {
          const msgExtension =
            message.ext.msg_extension && JSON.parse(message.ext.msg_extension)
          const options = {
            confrId: message.ext.conferenceId,
            password: message.ext.password || '',
            gid: msgExtension.group_id,
            inviter: msgExtension.inviter
          }
          webIM.call && webIM.call.listener.onInvite(message.from, options)
        })
      }
    },
    // 收到图片消息
    onPictureMessage(msg) {
      const { from, to, type, time, id } = msg
      const bySelf = from === this.userInfo.imUserName
      const message = {
        chatType: type,
        msg: msg.url,
        bySelf,
        type: 'img',
        id,
        to,
        from,
        time: Number(time),
        status: MSG_STATUS.UNREAD
      }

      this.addMessage(message)
      type === CHAT_TYPE.CHAT &&
        IMSDK.awaitInit()
          .then((im) => im.ack(msg))
          .catch(() => {
            debugger
          })
    },
    onCmdMessage(msg) {
      msg = parseMsgPayload(msg)
      const yxtMsgType = msg.ext?.yxtMsgType
      if (!yxtMsgType) return

      // 1-督促  2-分享  3-公告   4-群公告
      if ([1, 2, 3, 4].indexOf(yxtMsgType) !== -1) {
        EventBus.$emit('IM:getCmdMessage', msg)
        // 51-项目 52-成长体系
      } else if (yxtMsgType >= 50) {
        EventBus.$emit('IM:getCmdMessage', msg)
        // hack
        if (!window.handleImMessageList) {
          window.handleImMessageList = []
        }
        if (window.handleImMessageList.length) {
          window.handleImMessageList.forEach((callback) => {
            callback(msg)
          })
        }
      }
    },
    // 消息撤回
    onRecallMessage(msg) {
      msg.status = MSG_STATUS.RECALL
      msg.msg = `${this.$t('h5_IM_adversary')}${this.$t('h5_IM_recall_msg')}`
      // context.commit(MUTATIONSTYPES.DELETE_MESSAGE, { mid, to, chatType })
      const { chatType, contacterId } = this.selectedContact
      console.log('this.selectedContact', this.selectedContact)
      this.$store.commit(MUTATIONS_TYPES.DELETE_MESSAGE, {
        chatType,
        to: contacterId,
        mid: msg.mid
      })
      this.$store.commit(MUTATIONS_TYPES.UPDATE_MSG_LIST, {
        chatType,
        chatId: contacterId,
        msg: this.$t('h5_IM_recall_msg'),
        bySelf: false,
        time: new Date().getTime(),
        id: msg.mid,
        status: 'unread',
        type: 'groupNotice',
        to: this.userInfo.imUserName,
        from: contacterId,
        phimRecall: true,
        customExts: []
      })
    },
    // 收到消息送达服务器回执
    onReceivedMessage(msg) {
      this.$store.commit(MUTATIONS_TYPES.UPDATE_MESSAGE_ID, msg)
    },
    onError(e) {
      if (!IMSDK.statusCode) {
        return
      }
      switch (e.type) {
        case IMSDK.statusCode.WEBIM_CONNCTION_USER_NOT_ASSIGN_ERROR:
          console.warn('IM未登录')
          // this.$message.error('IM未登录')
          break
        case IMSDK.statusCode.WEBIM_CONNCTION_OPEN_ERROR:
          console.warn('IM登录失败')
          // this.$message.error('IM登录失败')
          break
        case IMSDK.statusCode.WEBIM_CONNCTION_GETROSTER_ERROR:
          console.warn('IM获取 Chat token 失败')
          // this.$message.error('IM获取 Chat token 失败')
          break
        case IMSDK.statusCode.WEBIM_CONNCTION_DISCONNECTED:
          // this.$message.error('IMWebSocket 断开连接, 正在重连')
          console.warn('IMWebSocket 断开连接, 正在重连')
          break
        case IMSDK.statusCode.WEBIM_CONNECTION_ERROR: // 用户鉴权失败
          // this.$message.error('IM用户鉴权失败')
          console.warn('IM用户鉴权失败')
          break
        case IMSDK.statusCode.WEBIM_CONNECTION_CLOSED: // 退出或未登录
          // this.$message.error('IM退出或未登录')
          console.warn('IM退出或未登录')
          break
        case IMSDK.statusCode.WEBIM_CONNCTION_USER_LOGIN_ANOTHER_DEVICE:
          // this.$message.error('IM用户在其他设备登录')
          console.warn('IM用户在其他设备登录')
          break
        case IMSDK.statusCode.WEBIM_CONNCTION_APPKEY_NOT_ASSIGN_ERROR: // 未设置 App Key
        case IMSDK.statusCode.WEBIM_CONNCTION_CLIENT_OFFLINE: // 未登录就发送消息
        case IMSDK.statusCode.WEBIM_CONNCTION_AJAX_ERROR: // AJAX 请求非成功状态
        case IMSDK.statusCode.WEBIM_CONNCTION_CALLBACK_INNER_ERROR: // 消息发送成功的回调函数内部错误
        case IMSDK.statusCode.GROUP_NOT_EXIST: // 群组不存在
        case IMSDK.statusCode.WEBIM_LOAD_MSG_ERROR: // 消息回调函数内部错误
        case IMSDK.statusCode.GROUP_NOT_JOINED: // 不在群组内
        case IMSDK.statusCode.PERMISSION_DENIED: // 用户无权限
        case IMSDK.statusCode.SERVICE_NOT_ALLOW_MESSAGING_MUTE: // 被禁言
        case IMSDK.statusCode.SERVICE_NOT_ALLOW_MESSAGING: // 用户未在白名单中
        case IMSDK.statusCode.SERVICE_NOT_ENABLED: // 服务未开启
        case IMSDK.statusCode.MESSAGE_RECALL_TIME_LIMIT: // 撤回消息超时
        case IMSDK.statusCode.SERVER_UNKNOWN_ERROR: // 消息发送失败未知错误
        case IMSDK.statusCode.MESSAGE_EXTERNAL_LOGIC_BLOCKED: // 消息被拦截
        case IMSDK.statusCode.MESSAGE_INCLUDE_ILLEGAL_CONTENT: // 敏感词
        case IMSDK.statusCode.WEBIM_CONNCTION_USER_KICKED_BY_OTHER_DEVICE: // 用户被踢下线
        case IMSDK.statusCode.WEBIM_CONNCTION_USER_KICKED_BY_CHANGE_PASSWORD: // 用户密码更新
        case IMSDK.statusCode.WEBIM_CONNCTION_USER_REMOVED: // 用户已经被注销
        case IMSDK.statusCode.WEBIM_DOWNLOADFILE_ERROR: // 下载文件失败
        case IMSDK.statusCode.WEBIM_UPLOADFILE_NO_LOGIN: // 未登录上传文件
        case IMSDK.statusCode.WEBIM_UPLOADFILE_ERROR: // 上传文件失败
          console.warn(e)
          break
        default:
          console.warn(e)
          break
      }
    },
    async addMessage(message) {
      try {
        const user = { name: message.from }
        if (message.chatType === 'groupchat') {
          user.groupId = message.to
        }
        message.user = await ImApiUtil.getUserByImName(user)
        message.read = []
        this.$store.commit(MUTATIONS_TYPES.UPDATE_MSG_LIST, message)
      } catch (e) {
        console.error(e)
      }
    },
    closeIM() {
      if (IMSDK.conn) {
        IMSDK.conn.close()
        // 重置拉取历史消息接口的游标
        IMSDK.conn.mr_cache = []
      } else {
        console.warn('im conn is undefined')
      }
      this.$store.commit(MUTATIONS_TYPES.CLEAR_MESSAGE)
      sessionStorage.removeItem('IMuserInfo')
      sessionStorage.removeItem('IMcurChatType')
      sessionStorage.removeItem('IMuserInfo')

      // 删除督促弹窗
      const urgeToast = document.getElementsByClassName('IM-urge-toast', 'div')
      if (urgeToast.length) {
        Array.prototype.forEach.call(urgeToast, (item) => {
          item.remove()
        })
      }
    }
  },
  beforeDestroy() {
    this.closeIM()
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/index.scss';

.yxt-nav {
  display: flex;
  justify-content: flex-end;
  height: 72px;
  padding: 24px 116px;
  background: #fff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.06);
}

.bell {
  width: 100px;
  height: 100px;
}
</style>
