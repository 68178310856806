const feConfig = window?.feConfig
const apiEnv = feConfig?.apiEnv
const feConfigAll = Object.assign({}, feConfig?.common, feConfig[apiEnv])

export const imagesBaseUrl =
  feConfigAll.imagesBaseUrl || 'https://images.yxt.com/'
export const easemobWebsdk =
  window.__EASEMOB_WEBSDK__ || `${imagesBaseUrl}stc/js/IM/websdk3.3.2.js`

// export const yxtwebimWebsdk = `https://meetcdn.yxt.com/sdk/imweb/sdk/YxtWebIM-dev.js?v=` + Date.now() // 自研sdk

// export const yxtwebimWebsdk = `${feConfigAll.staticBaseUrl}assets/84b23970/25cd6983/yxtwebim-min.js`
export const yxtwebimWebsdk =
  `${feConfigAll.staticBaseUrl}assets/b08d1e1e/a3ccf03e/yxtwebim-min_new.js?v=` +
  Date.now()
