import { loadScript, getUserToken } from './utils'
import {
  setApiConfig,
  getUserPw,
  IMsdkApi,
  transformIMsdkApi
} from './service.js'
import { easemobWebsdk, yxtwebimWebsdk } from './constant'
import { configBaseIm } from './config'
export { ImApiUtil } from './service.js'
console.log('test=========test=========test=========test')

const IMType = {
  EASEMOB: 'easemob',
  XXIM: 'xxim'
}

const IM_CHAT_MAP = new Map()
IM_CHAT_MAP.set('0', 'close')
IM_CHAT_MAP.set('1', 'xxim')
IM_CHAT_MAP.set('2', 'easemob')

class IMSDKCLASS {
  constructor() {
    if (IMSDKCLASS.instance) {
      console.warn('无法重复初始化, 返回第一次的实例')
      return
    }
  }

  static instance = window._IMSDK_ || null

  static factory() {
    if (IMSDKCLASS.instance) {
      console.warn('IMSDKCLASS 重复实例化, 返回第一次的实例')
      return IMSDKCLASS.instance
    }
    window._IMSDK_ = IMSDKCLASS.instance = new IMSDKCLASS()
    return IMSDKCLASS.instance
  }

  options = {
    domain: ''
  }
  IMsdkApi = null
  type = null
  connectionParams = {} // 建立连接所需要的参数

  _connection = null
  _init = {
    successCallback: [],
    errorCallback: [],
    initializing: null,
    initialized: false,
    IMInstance: null
  }

  async awaitOpen() {
    if (!this.type || this.type === 'close') return
    await this.awaitInit()
    return this.open()
  }

  get config() {
    return { ...configBaseIm, ...this.connectionParams, IM_STATUS: this.type }
  }

  get API() {
    return this.IMsdkApi
  }

  // @asserts
  get webIM() {
    return this.IMInstance
  }

  get message() {
    return this.IMInstance.message
  }

  get statusCode() {
    return this.IMInstance.statusCode
  }

  get IMInstance() {
    return this._init.IMInstance
  }

  getIMInstance() {
    return this._init.IMInstance
  }

  get conn() {
    return this._connection
  }

  get apiEnv() {
    // 获取imh5配置信息
    try {
      const { apiEnv } = window.feConfig
      return apiEnv
    } catch (e) {
      throw Error('未获取到配置中心 apiEnv 配置信息')
    }
  }

  prepareOptions(options) {
    Object.assign(this.options, options)
    const { imApi } = this.options

    if (!imApi) throw new Error('缺少参数 imApi')
    setApiConfig(imApi)
  }

  async init(options) {
    if (this._init.initializing) return this._init.initializing
    if (this._init.initialized) {
      return this._init.IMInstance
    }
    this._init.initializing = new Promise((resolve, reject) => {
      this._init.successCallback.push(resolve)
      this._init.errorCallback.push(reject)
    })

    Object.assign(this.options, options)

    try {
      const { reset = false, type } = this.options
      this.type = type || IMType.EASEMOB

      const token = getUserToken()

      if (!token) throw new Error('缺少 token')

      let connectionParams = null

      const user = await getUserPw({ resetTokenCache: reset })
      const appKey = `${user.hxOrgName}#${user.hxAppName}`

      this.type = IM_CHAT_MAP.get(user.imFactor)
      if (this.type === 'close') {
        // IM未开启，直接return
        return false
      }
      if (!this.type) this.type = IMType.EASEMOB // 容错处理，取不到值就使用环信IM能力
      const apiUrlCode = window.localStorage.orgId
      const apiEnv = this.apiEnv || 'dev'
      let { scPaasBaseUrl } = feConfig.common
      if (/prod$/.test(feConfig.apiEnv) && !scPaasBaseUrl) {
        scPaasBaseUrl = 'https://api-paas.yunxuetang.cn/'
      }
      const apiURL = scPaasBaseUrl + 'xxim/api/v1/' + apiUrlCode
      const wsURL = scPaasBaseUrl.replace(/http?s/, 'wss') + 'xximgateway'

      window.IMH5_SDK = {
        type: this.type,
        name: 'IMH5_SDK',
        apiEnv,
        apiURL,
        wsURL
      }

      switch (this.type) {
        case IMType.EASEMOB: {
          connectionParams = {
            ...this.options?.easemobParams
          }
          connectionParams.user = user.hxUserName
          connectionParams.accessToken = user.hxUserToken
          connectionParams.appKey = appKey
          this.IMsdkApi = IMsdkApi
          await loadScript(easemobWebsdk)
          const { WebIM } = window
          this._init.IMInstance = WebIM
          break
        }
        case IMType.XXIM: {
          // TODO 暂时写死
          connectionParams = {
            apiURL,
            wsURL,
            appKey: 'anykey',
            user: user.zyUsername,
            accessToken: user.zyUserToken
          }
          await loadScript(yxtwebimWebsdk)
          const { YxtWebIM } = window
          this._init.IMInstance = YxtWebIM
          break
        }
      }

      this.connectionParams = connectionParams
      this._init.successCallback.forEach((fn) => fn(this._init.IMInstance))
      this._init.initialized = true
      return
    } catch (e) {
      this._init.errorCallback.forEach((fn) => fn(e))
      this._init.IMInstance = null
      this._init.initializing = false
      throw e
    }
  }

  async awaitIMAPI() {
    await this.init()
    if ([IMType.EASEMOB].includes(this.type)) return this.IMsdkApi

    const conn = await this.connection()
    await this.awaitOpen()
    this.IMsdkApi = {
      ...conn,
      getUnreadMsgIdList: transformIMsdkApi.getUnreadMsgIdList(conn),
      searchUnplayed: conn.searchUnplayed,
      msgRead: conn.msgRead,
      getUnreadMsgCount: transformIMsdkApi.getUnreadMsgCount(conn),
      updateSingleChatStatus: conn.updateSingleChatStatus,
      changeAllMsgStatus: transformIMsdkApi.changeAllMsgStatus(conn),
      allnonsystemread: transformIMsdkApi.allnonsystemread(conn),
      putContactsTop: conn.putContactsTop,
      putContactsUnTop: conn.putContactsUnTop,
      updateSystemMsgStatus: conn.updateSystemMsgStatus,
      noticeRead: transformIMsdkApi.noticeRead(conn),
      async addContactUser(params) {
        const { contacterId } = params
        // name为undefined 默认取对方用户名
        return await conn.addContactUser(contacterId, 'user')
      },
      getContactsLists: transformIMsdkApi.getContactsLists(conn)
    }
    Object.setPrototypeOf(this.IMsdkApi, conn.__proto__)
    return this.IMsdkApi
  }

  async awaitInit() {
    if (this._init.initializing) return this._init.initializing
    return new Promise((resolve, reject) => {
      this._init.successCallback.push(resolve)
      this._init.errorCallback.push(reject)
    })
  }

  async connection() {
    await this.init()
    if (this._connection) return this._connection
    const { appKey, url, apiURL, Host, wsURL } = this.connectionParams
    if (this.IMInstance) {
      console.log('IMInstance 实例生成----')
      this._connection = new this.IMInstance.connection({
        ...configBaseIm,
        appKey,
        url,
        apiURL,
        wsURL,
        Host
      })
    }
    return this._connection
  }

  _openAsync = null

  open() {
    const { statusCode } = this.IMInstance || {}
    if (this._openAsync) return this._openAsync
    this._openAsync = this.connection().then((conn) => {
      return new Promise((resolve, reject) => {
        try {
          const resolveCallback = () => {
            resolve(conn)
            this._openAsync = null
          }
          console.debug(
            'im track, open',
            JSON.stringify(this.connectionParams),
            conn
          )
          conn.open(this.connectionParams)
          conn.listen({
            onOpened: () => {
              console.debug('im track, opened')
              resolveCallback()
            },
            onError: (e) => {
              console.debug('im track, listen->onError', JSON.stringify(e))
              this._openAsync = null
              if (!statusCode) {
                console.error('绚信错误码和环信不一致')
                return
              }
              if (e.type === statusCode.WEBIM_CONNCTION_OPEN_ERROR) {
                reject(e)
              }
            }
          })
        } catch (e) {
          reject(e)
        }
      })
    })

    return this._openAsync
  }

  ack(message) {
    // 绚信IM没有read 类型，直接跳过
    if (this.type === 'xxim') return
    const im = this.IMInstance
    const bodyId = message.id
    const msg = new im.message('read', im.conn?.getUniqueId())
    msg.set({
      id: bodyId,
      to: message.from
    })
    this.conn.send(msg.body)
  }
}

const IMSDK = IMSDKCLASS.factory()
export default IMSDK
