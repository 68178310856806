<template>
  <transition name="yxt-notification-fade">
    <div
      v-show="visible"
      :class="[
        'im-yxt-notification im-urge-toast',
        customClass,
        horizontalClass
      ]"
      :style="positionStyle"
      role="alert"
    >
      <i
        v-if="type || iconClass"
        class="yxt-notification__icon"
        :class="[typeClass, iconClass]"
      >
      </i>
      <div
        class="yxt-notification__group"
        :class="{ 'is-with-icon': typeClass || iconClass }"
      >
        <div class="yxt-notification__img" @click.stop="showChat">
          <img src="@IM/assets/urge/bell.svg" />
        </div>
        <div class="yxt-notification__content">
          <div class="content__title" @click.stop="showChat">
            {{ $t('pc_IM_urge_msg_tip').d('督促提醒') }}
          </div>
          <div class="content__txt" @click.stop="showChat">
            <span>
              <yxtbiz-user-name :name="userName" />
            </span>
            {{ $t('h5_IM_urge_you') }}
          </div>
        </div>
        <div class="el-notification__closeBtn" @click.stop="close">
          <img src="@IM/assets/urge/close.svg" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
import EventBus from '@IM/utils/eventBus'
import { urgeMsgBlacklist } from '@/utils'

const typeMap = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error'
}

export default {
  data() {
    return {
      visible: false,
      title: '',
      message: '',
      duration: 3000,
      type: '',
      showClose: true,
      customClass: '',
      iconClass: '',
      onClose: null,
      onClick: null,
      closed: false,
      verticalOffset: 0,
      timer: null,
      dangerouslyUseHTMLString: false,
      position: 'top-right',
      userName: '',
      msgId: '',
      noticeIndex: '',
      fromHxUser: ''
    }
  },

  computed: {
    userInfo() {
      return this.$store.state.IMuserInfo
    },
    typeClass() {
      return this.type && typeMap[this.type]
        ? `el-icon-${typeMap[this.type]}`
        : ''
    },

    horizontalClass() {
      return this.position.indexOf('right') > -1 ? 'right' : 'left'
    },

    verticalProperty() {
      return /^top-/.test(this.position) ? 'top' : 'bottom'
    },

    positionStyle() {
      return {
        [this.verticalProperty]: `${this.verticalOffset}px`
      }
    }
  },

  watch: {
    closed(newVal) {
      if (newVal) {
        this.visible = false
        this.$el.addEventListener('transitionend', this.destroyElement)
      }
    }
  },
  mounted() {
    if (this.duration > 0) {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.closed = true
          this.onClose()
        }
      }, this.duration)
    }
    document.addEventListener('keydown', this.keydown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown)
  },
  methods: {
    showChat() {
      EventBus.$emit('IM:showChat', this.fromHxUser)
      this.close()
      this.visible = false
    },
    destroyElement() {
      this.$el.removeEventListener('transitionend', this.destroyElement)
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    },

    click() {
      if (typeof this.onClick === 'function') {
        this.onClick()
      }
    },

    close() {
      this.closed = true
      urgeMsgBlacklist.setItem(this.userInfo.hxUserName, this.msgId)
      if (typeof this.onUserClose === 'function') {
        this.onUserClose(this.noticeIndex)
      }
      if (typeof this.onClose === 'function') {
        this.onClose()
      }
    },

    clearTimer() {
      clearTimeout(this.timer)
    },

    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.closed = true
            this.onClose()
          }
        }, this.duration)
      }
    },
    keydown(e) {
      if (e.keyCode === 46 || e.keyCode === 8) {
        this.clearTimer() // detele 取消倒计时
      } else if (e.keyCode === 27) {
        // esc关闭消息
        if (!this.closed) {
          this.closed = true
          this.onClose()
        }
      } else {
        this.startTimer() // 恢复倒计时
      }
    }
  }
}
</script>
