// 100=微信isv 101=中欧渠道 102=内容BU 103=线下渠道 104=钉钉isv
export function isISV() {
  const { sourceCode } = window.localStorage
  return sourceCode === '100' || sourceCode === '104' || sourceCode === '106'
}

export function isCeibs() {
  return window.localStorage.sourceCode === '101'
}

export function getVideoInfo(file) {
  return new Promise((resolve, reject) => {
    // 创建视频
    const video = document.createElement('video')
    video.controls = 'controls'
    video.crossOrigin = 'anonymous'

    video.src = URL.createObjectURL(file)
    console.log(video.src)
    // 这很重要
    video.currentTime = 1
    // 当前帧的数据已加载
    video.addEventListener('canplay', () => {
      // 创建画布
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth * 0.5
      canvas.height = video.videoHeight * 0.5
      canvas
        .getContext('2d')
        .drawImage(video, 0, 0, canvas.width, canvas.height)
      // 转码添加
      const src = canvas.toDataURL('image/png')
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
        imgSrc: src
      })
    })
  })
}

// 将base64转换为文件
export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export function formatDuration(value) {
  if (typeof value !== 'number') {
    return ''
  }
  const result = parseInt(value) / 1000
  const h = Math.floor(result / 3600)
  const m = Math.floor((result / 60) % 60)
  const s = Math.floor(result % 60)

  let res = ''
  if (h > 0) res += `${h.toString().padStart(2, '0')}:`
  res += `${m.toString().padStart(2, '0')}:`
  res += s.toString().padStart(2, '0')
  return res
}

export function debounce(fun, delay) {
  return function (...args) {
    const _this = this
    clearTimeout(fun._timer)
    fun._timer = setTimeout(function () {
      fun.apply(_this, args)
    }, delay)
  }
}

export const getUserToken = () => {
  return localStorage.getItem('token')
}

export const isDingDing = () => {
  return localStorage.sourceCode === '104'
}

export const parseMsgPayload = (msg) => {
  if (!msg) return msg

  if (typeof msg === 'string') {
    try {
      msg = JSON.parse(msg)
    } catch {}
  }
  if (typeof msg.ext === 'string') {
    try {
      msg.ext = JSON.parse(msg.ext)
    } catch {}
  }
  if (typeof msg.ext?.yxtMsgBody === 'string') {
    try {
      msg.ext.yxtMsgBody = JSON.parse(msg.ext.yxtMsgBody)
    } catch {}
  }
  if (typeof msg?.yxtMsgBody === 'string') {
    try {
      msg.yxtMsgBody = JSON.parse(msg.yxtMsgBody)
    } catch {}
  }

  return msg
}

export const urgeMsgBlacklist = {
  _sufix: 'UrgeMsgBlacklist',
  setItem(userName, msgId) {
    const key = `${userName}${this._sufix}`
    const urgeMsgBlacklist = localStorage.getItem(key)
    let blacklist = []
    try {
      blacklist = JSON.parse(urgeMsgBlacklist) || []
    } catch {}
    blacklist = Array.from(new Set([...blacklist, msgId]))
    localStorage.setItem(key, JSON.stringify(blacklist))
  },
  getItem(name) {
    try {
      const key = `${name}${this._sufix}`
      return JSON.parse(localStorage.getItem(key)) || []
    } catch {
      return []
    }
  }
}
