<template>
  <section class="im-multi-an-item">
    <single-item @go-link="goLink" :item="item" class="im-muli-header" />
    <ul class="im-multi-an-children">
      <li v-for="(node, key) in subList" :key="key">
        <div class="im-multi-an-img">
          <img :src="node.image" />
        </div>
        <div class="im-multi-an-content">
          <h4>{{ node.subject }}</h4>
          <yxtf-link
            @click="linkHandler(node)"
            type="primary"
            class='im-ann-link'
            :underline="false"
            >{{$t('h5_IM_click_view_detail')}}</yxtf-link
          >
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
// import FrontLink from '@yxt-pc/foreground/lib/conponents/link'
import SingleItem from './SingleItem'
export default {
  components: {
    SingleItem
    // yxtfLink: FrontLink
  },
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    subList () {
      return this.item.customExts.slice(1)
    }
  },
  methods: {
    linkHandler (node) {
      this.$emit('go-link', node)
    },
    goLink (node) {
      this.$emit('go-link', node)
    }
  }
}
</script>
