import MsgCenter from './MsgCenter.vue'

MsgCenter.install = function (Vue) {
  Vue.component(MsgCenter.name, MsgCenter)
}

export default MsgCenter

// import Bell from './Index.vue'

// Bell.install = function (Vue) {
//   Vue.component(Bell.name, Bell)
// }

// export default Bell
