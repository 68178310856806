<template>
  <div
    class="im-sidebar__customer openZhiChi"
    @click="handleClickSmartCustomer"
  >
    <img
      class="default"
      :src="`${staticBaseUrl}assets/148cf22d/ef884b03/smartcustormer.svg`"
    />
    <p class="hover">{{ $t('pc_IM_text_smart_customer') }}</p>
  </div>
</template>

<script>
import { staticBaseUrl } from '@/conost/index'
import { getOrgInfo } from '@IM/service/main.service'
export default {
  data() {
    return {
      staticBaseUrl
    }
  },
  components: {},
  mounted() {
    this.loadSmartCustomer()
  },
  unmounted() {
    this.unloadSmartCustomer()
  },
  methods: {
    unloadSmartCustomer() {
      const script = document.getElementById('zhichiScript')
      const parent = script && script.parentNode

      if (parent) {
        parent.removeChild(script)
      }
    },
    loadSmartCustomer() {
      this.unloadSmartCustomer()
      let operateLevel = window.localStorage.getItem('operateLevel') || '' // 客户档案运营分级(CSM),S/A/B/C
      let batchId = window.localStorage.getItem('batchId') || '' // 批次id,v5.1~v5.2期间临时使用: 取值参考: a001,a002
      function init (operateLevel, batchId) {
        // 智齿客服
        ;(function (w, d, e, x) {
          w[e] = function () {
            w.cbk = w.cbk || []
            w.cbk.push(arguments)
          }
          x = d.createElement('script')
          x.async = true
          x.id = 'zhichiScript'
          x.className = 'openZhiChi' // 该class绑定到自定义按钮上 第一步
          x.src =
            'https://ten.sobot.com/chat/frame/v2/entrance.js?sysnum=511c0a484f704e5591b7338d069b3bc5&channelid=2'
          d.body.appendChild(x)
        })(window, document, 'zc')
        // eslint-disable-next-line no-undef
        zc('config', {
          uname: window.localStorage.getItem('fullname') || '帮助中心',
          realname: window.localStorage.getItem('username') || 'admin',
          remark: '帮助中心', // 自定义备注
          source: 0,
          title_flag: 2,
          custom_title: '帮助中心客服平台',
          custom: true,
          partnerid: window.localStorage.getItem('userId') || '',
          params: JSON.stringify({
            level: operateLevel, // 客户档案运营分级(CSM),S/A/B/C
            p: batchId, // 批次id,v5.1~v5.2期间临时使用: 取值参考: a001,a002
            orgid: window.localStorage.getItem('orgId') || ''
          })
        })
      }
      if (!operateLevel && !batchId) {
        getOrgInfo(localStorage.getItem('orgId')).then(res => {
          if (res.operateLevel) localStorage.setItem('operateLevel', res.operateLevel)
          if (res.batchId) localStorage.setItem('batchId', res.batchId)
          init(res.operateLevel, res.batchId)
        }).catch(() => {
          init(operateLevel, batchId)
        })
      } else {
        init(operateLevel, batchId)
      }
    },
    handleClickSmartCustomer() {
      // console.log(111)
      // if (document.getElementById('zc__sdk__sys__btn')) {
      //   document.getElementById('zc__sdk__sys__btn').click()
      // }
    }
  }
}
</script>

<style scoped lang="scss"></style>
