export function minsDiff (beginTime, endTime) {
  // 计算分钟差值
  const diff = (parseInt(endTime) - parseInt(beginTime)) / (60 * 1000)
  return diff < 5
}

export function formatMsgs (msgs) {
  const msg = msgs.shift()
  let time = msg.time
  return msgs.reduce(
    (pre, cur) => {
      if (!minsDiff(time, cur.time) || cur.type === 'groupNotice') {
        time = cur.time
        pre.push({
          time: parseInt(time),
          type: cur.type,
          msgs: [cur],
          phimRecall: !!cur.phimRecall
        })
      } else {
        pre[pre.length - 1].msgs.push(cur)
      }
      return pre
    },
    [
      {
        time: parseInt(time),
        msgs: [msg],
        type: msg.type
      }
    ]
  )
}
