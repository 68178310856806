<template>
  <div>
    <div class="im-ai-robot-assistant" @click="handleShowAiRobot">
      <img
        :src="`${staticBaseUrl}assets/7dd6601c/439451ff/ai-robot.png`"
        alt=""
        srcset=""
      />
    </div>
    <yxtbiz-ai-robot v-if="showAiRobot" @close="close" mode="student"></yxtbiz-ai-robot>
  </div>
</template>
<script>
import { staticBaseUrl } from '@IM/conost'
export default {
  data() {
    return {
      showAiRobot: false,
      staticBaseUrl
    }
  },
  methods: {
    handleShowAiRobot() {
      this.showAiRobot = true
      this.$emit('changeIndex', 1000)
    },
    close() {
      this.showAiRobot = false
      this.$emit('changeIndex', 899)
    }
  }
}
</script>
<style lang="scss" scoped>
.im-add-xxx {
  width: 300px;
  height: 300px;
  right: 10px;
  bottom: 10px;
  background-color: aquamarine;
}
.im-ai-robot-assistant {
  height: 56px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 24px;
    height: 24px;
  }
}
</style>
