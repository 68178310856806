<template>
  <div class="im-common-notice">
    <h3>
      <span class="title">{{ node.subject }}</span>
      <yxt-tag
        v-if="showImportantTag(node.isImportant)"
        type="warning"
        size="mini"
        >重要</yxt-tag
      >
    </h3>
    <section v-if="node.content" class="im-notice-summary">
      <yxtbiz-open-data-html :html="node.content.split(/\n+/).join('<br />')" />
    </section>
    <yxtf-row class="im-notice-link" type="flex" align="center">
      <yxtf-col :span="12">
        <yxtf-link
          v-if="node.lintTarget && isShow"
          class="im-notice-link-span"
          :underline="false"
          type="primary"
          @click="goLink"
          >{{ $t('h5_IM_click_view_detail') }}</yxtf-link
        >
      </yxtf-col>
      <yxtf-col class="text-right font-size-12" :span="12">
        <span class="color-gray-6">{{
          node.date | formatNoticeCenterTime
        }}</span>
      </yxtf-col>
    </yxtf-row>
  </div>
</template>

<script>
// import { Row, Col } from 'yxt-pc'
// import FrontLink from 'yxt-pc/lib/front-link'
// import FrontLink from '@yxt-pc/foreground/lib/conponents/link'
import { formatNoticeCenterTime } from '../../filters/filters'

export default {
  components: {
    // yxtRow: Row,
    // yxtCol: Col,
    // yxtfLink: FrontLink
  },
  filters: {
    formatNoticeCenterTime
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    node() {
      return this.data.customExts[0]
    },
    isShow() {
      return this.node.isSupUrl === 1
    },
    isAssist5029() {
      return this.$route.params?.id === 'assist__50__29'
    }
  },
  methods: {
    goLink() {
      window.open(this.node.lintTarget)
    },
    showImportantTag(v) {
      return v === 1 && this.$route.params?.id === 'assist__50__29'
    }
  }
}
</script>
