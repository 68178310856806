export default [
  {
    id: 'assist__50__20',
    name: '人才发展1',
    type: 'talent',
    icon: 'im-develop'
  },
  {
    id: 'assist__50__21',
    name: '培训1',
    type: 'cultivate',
    icon: 'im-train'
  },
  {
    id: 'assist__50__22',
    name: '考试1',
    type: 'examine',
    icon: 'im-exam'
  },
  {
    id: 'assist__50__25',
    name: '调研1',
    type: 'investigation',
    icon: 'im-survey'
  },
  {
    id: 'assist__50__23',
    name: '社区1',
    type: 'community',
    icon: 'im-community'
  },
  {
    id: 'assist__50__29',
    name: '公告1',
    type: 'announcement',
    icon: 'im-ann'
  },
  {
    id: 'assist__50__24',
    name: '积分商城1',
    type: 'integral',
    icon: 'im-points'
  },

  {
    id: 'assist__50__26',
    name: '证书1',
    type: 'certificate',
    icon: 'im-cert'
  },
  {
    id: 'assist__50__27',
    name: '直播1',
    type: 'broadcast'
  },
  {
    id: 'assist__50__28',
    name: '系统通知1',
    type: 'notice',
    icon: 'im-system'
  }
]
