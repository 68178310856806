import Vue from 'vue'
import ImChat from '@/store/chat'
import Main from './main.vue'
import { isVNode } from './vdom'

const NotificationConstructor = Vue.extend(Main)

let instance
const instances = []
let seed = 1

const Notification = async function (options, $i18n) {
  options = Object.assign({}, options)
  const userOnClose = options.onClose
  const id = 'notification_' + seed++
  const position = options.position || 'top-right'

  options.onClose = function () {
    Notification.close(id, userOnClose)
  }

  instance = new NotificationConstructor({
    data: options,
    i18n: $i18n,
    store: ImChat
  })

  if (isVNode(options.message)) {
    instance.$slots.default = [options.message]
    options.message = 'REPLACED_BY_VNODE'
  }
  instance.id = id
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.dom = instance.$el
  instance.dom.style.zIndex = '1000'

  let verticalOffset = options.offset || 72
  instances
    .filter((item) => item.position === position)
    .forEach((item) => {
      verticalOffset += item.$el.offsetHeight + 16
    })
  verticalOffset += 16
  instance.verticalOffset = verticalOffset
  instances.push(instance)

  instance.visible = true
  return instance
}

;['success', 'warning', 'info', 'error'].forEach((type) => {
  Notification[type] = (options) => {
    if (typeof options === 'string' || isVNode(options)) {
      options = {
        message: options
      }
    }
    options.type = type
    return Notification(options)
  }
})

Notification.close = function (id, userOnClose) {
  let index = -1
  const len = instances.length
  const entity = instances.filter((item, i) => {
    if (item.id === id) {
      index = i
      return true
    }
    return false
  })[0]

  if (!entity) return

  if (typeof userOnClose === 'function') {
    userOnClose(entity)
  }
  instances.splice(index, 1)

  if (len <= 1) return
  const position = entity.position
  const removedHeight = entity.dom.offsetHeight
  for (let i = index; i < len - 1; i++) {
    if (instances[i].position === position) {
      instances[i].dom.style[entity.verticalProperty] =
        parseInt(instances[i].dom.style[entity.verticalProperty], 10) -
        removedHeight -
        16 +
        'px'
    }
  }
}

Notification.closeAll = function () {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close()
  }
}

export default Notification
