export const CHAT_TYPE = {
  // 单聊
  CHAT: 'chat',
  // 群聊
  CHAT_GROUP: 'groupchat'
}

export const CHAT_TYPE_BY_CONTACTTYPE = {
  // 单聊
  2: CHAT_TYPE.CHAT,
  // 群聊
  3: CHAT_TYPE.CHAT_GROUP
}

export const TYPES = [
  'notice',
  'urge',
  'share',
  'announcement',
  'groupAnt',
  'groupNotice',
  'groupNotice',
  'groupNotice',
  'groupNotice',
  'trainNotice',
  'img',
  'joinGroup',
  'shareImg'
]
