import axios from 'axios'
import { getUserToken } from '@IM/utils'
// axios 配置
function getInstance (serverPath, Token) {
  const http = axios.create({
    baseURL: serverPath,
    timeout: 60000,
    withCredentials: false
  })
  /* request拦截器 */
  http.interceptors.request.use(
    (config) => {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      config.headers.source = 501
      config.headers.token = getUserToken()
      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )
  /* respone拦截器 */
  http.interceptors.response.use(
    (res) => {
      if (res.data instanceof Blob) {
        return res
      }
      return Promise.resolve(res)
    },
    error => {
      return Promise.reject(error.response && error.response.data)
    }
  )
  return http
}

export default class {
  $apiPath = ''
  constructor (apiPath) {
    this.$apiPath = apiPath
  }

  async post (url, data) {
    const result = await getInstance(this.$apiPath).post(url, data)
    return result.data
  }

  async put (url, data) {
    const result = await getInstance(this.$apiPath).put(url, data)
    return result.data
  }

  async delete (url, data) {
    const result = await getInstance(this.$apiPath).delete(url, data)
    return result.data
  }

  async get (url, param = {}) {
    let newURL = url
    const reg = /{.*?}/
    let pathParam
    while ((pathParam = reg.exec(newURL)) !== null) {
      const regex = new RegExp(pathParam[0])
      newURL = newURL.replace(regex, param[pathParam[1]])
    }

    param.time = Date.now()
    const ajax = getInstance(this.$apiPath)
    const result = await ajax({
      url: newURL,
      method: 'get',
      params: param,
      withCredentials: false
    })
    return result.data
  }
}
