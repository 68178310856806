const feConfig = window.feConfig

if (!feConfig) {
  throw new Error('无法获取初始化配置, feConfig 没有定义')
}

export const env = feConfig.apiEnv

export const domain = Object.assign(
  {},
  feConfig?.common,
  feConfig[env || process.env.VUE_APP_APIENV]
)

export const source = 501
