(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vue"), require("Vuex"), require("YXTBIZ"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["Vue", "Vuex", "YXTBIZ", "axios"], factory);
	else if(typeof exports === 'object')
		exports["IM"] = factory(require("Vue"), require("Vuex"), require("YXTBIZ"), require("axios"));
	else
		root["IM"] = factory(root["Vue"], root["Vuex"], root["YXTBIZ"], root["axios"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__2740__, __WEBPACK_EXTERNAL_MODULE__4033__, __WEBPACK_EXTERNAL_MODULE__43__, __WEBPACK_EXTERNAL_MODULE__3300__) {
return 