<template>
  <div class="im-urge"></div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { urgeMsgBlacklist } from '@/utils'

import Notify from './notification'

export default {
  name: 'NoticeUrge',
  components: {},
  inject: ['platform'],
  data() {
    return {
      queue: [],
      queueCopy: [],
      index: 0,
      toastVisible: false,
      timer: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'unreadMsgsUrge'])
  },
  watch: {
    queue: {
      deep: true,
      immediate: true,
      handler(val) {
        // 如果是管理端则不提示通知
        if (this.platform === 'manager') {
          return
        }
        console.log('watch queue', val)
        // 如果刚加载只有一条
        // if (val.length > 2) {
        //   this.getElementsByClassName('im-urge-toast', 'div').forEach(
        //     (item) => {
        //       item.remove()
        //     }
        //   )

        //   return
        // }
        // this.showNotify()

        // 如果小于2条
        // if (val.length === 1 && this.queueCopy.length === 1) {
        //   Notify(
        //     {
        //       userName: val[0].fullName,
        //       msgId: val[0].msgId,
        //       duration: 0,
        //       fromHxUser: val[0].fromUser
        //     },
        //     this.$i18n
        //   )
        //   return
        // }

        // if (val.length === 2 && this.queueCopy.length === 1) {
        //   const urgeMsg = val.filter((item) => this.queueCopy[0] !== item.msgId)
        //   Notify(
        //     {
        //       userName: urgeMsg.fullName,
        //       msgId: urgeMsg.msgId,
        //       duration: 0,
        //       fromHxUser: val[0].fromUser
        //     },
        //     this.$i18n
        //   )
        //   return
        // }

        // if (val.length === 2 && this.queueCopy.length === 2) {
        //   this.showInitialNotify(val)
        // }

        if (!val.length) {
          this.getElementsByClassName('im-urge-toast', 'div').forEach(
            (item) => {
              item.remove()
            }
          )
        } else {
          this.showNotify()
        }
      }
    },
    unreadMsgsUrge: {
      deep: true,
      immediate: true,
      handler(val, latest) {
        if (val !== latest) {
          this.getUnreadUrgeMsgList(val)
        }
      }
    }
  },
  async mounted() {
    console.log('%c urge组件 ', 'color: #fff;background: blue;')
    await this.initAppData()
    await this.updateUnreadMsg()
  },

  beforeDestroy() {
    Notify.closeAll()
    clearInterval(this.timer)
  },

  methods: {
    ...mapActions(['initAppData', 'updateUnreadMsg']),
    showInitialNotify(val) {
      Notify(
        {
          userName: val[0].fullName,
          msgId: val[0].msgId,
          duration: 0,
          fromHxUser: val[0].fromUser
        },
        this.$i18n
      )

      if (val[1]) {
        this.timer = setTimeout(() => {
          Notify(
            {
              userName: val[1].fullName,
              msgId: val[1].msgId,
              duration: 0,
              fromHxUser: val[1].fromUser
            },
            this.$i18n
          )
        }, 2000)
      }
    },
    getElementsByClassName(className, tagName) {
      const ele = []
      const all = document.getElementsByTagName(tagName || '*')
      for (let i = 0; i < all.length; i++) {
        if (
          all[i].className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
        ) {
          ele[ele.length] = all[i]
        }
      }
      return ele
    },
    getUnreadUrgeMsgList(datas) {
      // 获取本地黑名单
      const { hxUserName } = this.userInfo || {}
      const blackList = urgeMsgBlacklist.getItem(hxUserName)
      // 如果没有赋值
      if (!datas?.length) {
        this.queue = []
        return
      }
      const msgList = datas.filter((msg) => blackList.indexOf(msg.msgId) === -1)
      const msgListIds = msgList.reduce((cur, item) => {
        cur.push(item.msgId)
        return cur
      }, [])
      const arr = Array.from(new Set([...this.queueCopy, ...msgListIds]))

      if (
        this.queueCopy.length === msgListIds.length &&
        arr.length === this.queueCopy.length
      ) {
        return
      }

      this.index = 0
      this.queue = msgList
      this.queueCopy = msgListIds
    },
    /*
     * 停止循环 隐藏所有督促
     */
    hideAllNotify() {
      this.getElementsByClassName('im-urge-toast', 'div').forEach((item) => {
        item.remove()
      })
    },
    showNotify() {
      // 如果是管理端则不提示通知
      if (this.platform === 'manager') {
        return
      }
      if (this.timer) clearTimeout(this.timer)
      const loopShow = () => {
        if (!this.queue.length) {
          return clearTimeout(this.timer)
        }
        if (this.index > this.queue.length - 1) {
          // this.hideAllNotify()
          this.index = 0
        }
        const msg = this.queue[this.index]
        console.log('show::', this.index, this.queue)
        Notify(
          {
            userName: msg.fullName,
            msgId: msg.msgId,
            fromHxUser: msg.fromUser,
            noticeIndex: this.index,
            onUserClose: (noticeIndex) => {
              this.queue.forEach(m => {
                urgeMsgBlacklist.setItem(this.userInfo.hxUserName,  m.msgId)
              })
              this.queue = []
              // this.queue = this.queue.filter((val, i) => noticeIndex !== i)
              // if (this.queue.length < 3) {
              //   clearInterval(this.timer)
              // setTimeout(() => {
              //   this.showInitialNotify(this.queue)
              // }, 1000)
              // }
            }
          },
          this.$i18n
        )
        this.index = this.index + 1
        if (this.queue?.length > 0) {
          this.timer = setTimeout(loopShow, 2500)
        }
      }
      this.timer = setTimeout(loopShow, 2500)
    }
  }
}
</script>
