export default {
  UPDATE_USER_LIST: 'UPDATE_USER_LIST',
  UPDATE_MSG_LIST: 'UPDATE_MSG_LIST',
  UPDATE_MSGS_LIST: 'UPDATE_MSGS_LIST',
  UPDATE_MESSAGE_ID: 'UPDATE_MESSAGE_ID',
  UPDATE_MESSAGE_STATUS: 'UPDATE_MESSAGE_STATUS',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  UPDATE_AUDIO_STATUS: 'UPDATE_AUDIO_STATUS',
  SET_USER_INFO: 'setIMUserInfo',
  SET_CONTACTLIST: 'set_contactList',
  SET_IMDIALOGVISIBLE: 'set_imDialogVisible',
  SET_ACTIVEUSER: 'set_ActiveUser',
  SET_FULLPAGELOADING: 'set_fullPageLoading',
  SET_UNREADMSGS: 'set_unreadMsgs',
  SET_PREVIEW_STATUS: 'setPreviewStatus'
}
