<template>
  <yxtf-dialog
    v-if="noticeItem"
    :title="$t('pc_IM_notice_dialog_title').d('公告')"
    width="520px"
    custom-class="im-notice-dialog"
    :before-close="handleClose"
    :visible.sync="visible"
    :modal-append-to-body="false"
  >
    <img
      v-if="noticeItem.image"
      :src="noticeItem.image"
      class="im-notice-dialog__img"
    />
    <h4 class="im-notice-dialog__title" :class="titleStatus">
      <span
        v-if="noticeItem.isImportant === 1"
        class="im-notice-dialog__title__icon"
        >{{ $t('pc_IM_notice_dialog_important').d('重要') }}</span
      >
      <b :title="noticeItem.subject" class="im-notice-dialog__title--bold">{{
        noticeItem.subject
      }}</b>
    </h4>
    <p
      v-if="noticeItem.content"
      :title="noticeItem.content"
      class="im-notice-dialog__desc"
    >
      {{ noticeItem.content }}
    </p>
    <span
      class="im-notice-dialog__detail"
      :class="{ 'im-notice-dialog__detail--visible': !hasSubNoticeItems }"
      @click="handleClick(noticeItem)"
      >{{ $t('pc_IM_notice_dialog_detail').d('查看详情') }}</span
    >

    <template v-if="hasSubNoticeItems">
      <div
        v-for="subItem in subNoticeItems"
        :key="subItem.id"
        class="im-notice-dialog__item"
      >
        <img
          v-if="subItem.image"
          :src="subItem.image"
          class="im-notice-dialog__item__img"
        />

        <div class="im-notice-dialog__item__info">
          <b class="im-notice-dialog__title--bold" :title="subItem.subject">{{
            subItem.subject
          }}</b>
          <div>
            <span
              class="im-notice-dialog__detail"
              @click="handleClick(subItem)"
              >{{ $t('pc_IM_notice_dialog_detail').d('查看详情') }}</span
            >
          </div>
        </div>
      </div>
    </template>

    <div v-if="!hasSubNoticeItems" class="im-notice-dialog__footer">
      <yxtf-button type="primary" @click="handleClick(noticeItem)">{{
        $t('pc_IM_notice_dialog_detail').d('查看详情')
      }}</yxtf-button>
    </div>
  </yxtf-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { noticeRead, noticeGraphicRead } from '@IM/service/main.service'
import UniqueKeyStorage from '@IM/utils/uniqueKeyStorage'

const userId = localStorage.getItem('userId')
const storage = new UniqueKeyStorage('notice_dialog_msg_id')

export default {
  name: 'NoticeDialog',

  data() {
    return {
      visible: false,
      noticeItem: null,
      subNoticeItems: []
    }
  },

  computed: {
    ...mapGetters(['unreadMsgsNoticePop']),
    data() {
      return this.unreadMsgsNoticePop
    },
    // 公告列表
    content() {
      const list = this.data?.payload?.ext?.yxtMsgBody.content || []
      return list
    },

    hasSubNoticeItems() {
      return this.subNoticeItems.length > 0
    },

    titleStatus() {
      return this.noticeItem.content
        ? 'im-notice-dialog__title--ellipsis'
        : 'im-notice-dialog__title--break'
    }
  },

  watch: {
    content: {
      immediate: true,
      deep: true,
      handler(val) {
        console.debug('content', val)
        if (val.length > 0) {
          if (!storage.has(`${userId}_${this.data.msgId}`)) {
            this.visible = true
          }
          this.noticeItem = val[0]
          this.subNoticeItems = val.slice(1)
        }
      }
    }
  },
  methods: {
    handleClose(done) {
      if (this.data) {
        storage.set(`${userId}_${this.data.msgId}`)
      }
      done()
    },

    async handleClick(item) {
      const { fromUser, msgId } = this.data
      try {
        if (item.id) {
          // 这个是运营那边的接口，用来统计是否已读的，对下面的设置已读接口无任何影响
          await noticeGraphicRead(item.id)
        }
        if (msgId && fromUser) {
          await noticeRead(msgId)
          this.visible = false
        }
      } catch (e) {
        this.$message.error(e.message || '设置为已读消息失败')
      } finally {
        window.location.href = item.lintTarget
      }
    }
  }
}
</script>
