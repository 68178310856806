import { commonUtil } from 'yxt-biz-pc'
export const i18n = commonUtil.i18n

export default {
  async initLang() {
    try {
      const setLanguage = () => {
        return commonUtil.setLanguage({
          modules: ['im'], // 模块
          appTypes: ['pc', 'h5']
        })
      }
      await setLanguage()
    } catch (e) {
      // debugger
      console.error(e)
      // this.$message.error((e && e.message) || String(e))
    }
  }
}
