import Vue from 'vue'
import { commonUtil } from 'yxt-biz-pc'
import debounce from 'lodash/debounce'
import sUtils from '@IM/store/utils'
import { enmuFunctions } from '@IM/conost'
import IMSDK, { ImApiUtil } from '../im-sdk/index.js'
import { domain } from '@IM/utils/getConfig'
import { CHAT_TYPE, TYPES } from '@IM/conost/chatType'
import { CHAT_TYPE_BY_CONTACTTYPE } from '../conost/chatType'
import { getUserToken, isDingDing, parseMsgPayload } from '@/utils'
import { setHsitoryMsgOpts } from './setHsitoryMsgOpts'
import MUTATIONSTYPES from './chatMutationsTypes'
import { minsDiff } from '../utils/formatMsgs'
import {
  getContactsLists,
  addContactUser,
  getUnreadMsgIdList
} from '../service/main.service'

let __imInitTimer__ = null
// const __imPromiseTimer__ = {}

const Chat = {
  state: {
    isPreviewing: false,
    userList: {
      contactUserListLoading: false,
      contactUserList: null,
      groupUserList: [],
      chatroomUserList: []
    },
    msgList: {
      chat: {},
      groupchat: {}
    },
    userPw: {},
    IMuserInfo: null,
    userName: null,
    appInitStatus: 0, // 初始化状态，0:未初始化，1:已初始化
    IMStatus: null, // IM要素状态，为2时代表开启
    onlineHelpStatus: null, // 在线客服要素状态，为2时代表开启
    downloadAppStatus: null, // 下载app的要素状态
    showAi: null,
    aiRobotStatus: null, // 问一问助手要素状态
    appKey: '',
    IMDialogVisible: false,
    contactList: {
      loading: false,
      value: []
    },
    activeUser: null,
    fullPageLoading: false,
    unreadMsgs: {
      loading: false,
      value: []
    }
  },
  mutations: {
    [MUTATIONSTYPES.UPDATE_USER_LIST](state, payload) {
      // 更新用户列表
      const { userList, type } = payload
      state.userList[type] = userList
    },
    [MUTATIONSTYPES.UPDATE_MSGS_LIST](state, msgs = []) {
      // 更新聊天信息列表
      if (!msgs.length) return
      const msg = msgs[0].msgs[0]
      const chatType = msg.chatType
      let chatId

      if (chatType === 'groupchat') {
        chatId = msg.to
      } else {
        chatId = msg.bySelf ? msg.to : msg.from
      }
      let list = state.msgList[chatType][chatId] || []
      if (list.length > 0) {
        const listMsgs = list[0].msgs
        const msgsList = msgs[0].msgs
        const newArr = []
        for (let i = 0, len = listMsgs.length; i < len; i++) {
          if (msgsList.findIndex((item) => item.id === listMsgs[i].id) === -1) {
            newArr.push(listMsgs[i])
          }
        }
        if (newArr.length === 0) {
          list = []
        } else {
          list[0].msgs = newArr
        }
      }

      // state.msgList[chatType][chatId] = [...msgs, ...list].sort((a, b) => {
      //   return a.time > b.time
      // })
      state.msgList[chatType][chatId] = [...msgs, ...list]
      state.msgList = Object.assign({}, state.msgList)
    },
    [MUTATIONSTYPES.UPDATE_MSG_LIST](state, msg) {
      // 发送消息时走的更新消息列表逻辑
      const { chatType = CHAT_TYPE.CHAT } = msg
      let chatId
      if (msg.chatType === 'groupchat') {
        chatId = msg.to
      } else {
        chatId = msg.bySelf ? msg.to : msg.from
      }

      if (!state.msgList[chatType][chatId]) {
        if (msg.bySelf) {
          state.msgList[chatType][chatId] = []
        } else {
          return
        }
      }

      if (!state.msgList[chatType][chatId].length) {
        state.msgList[chatType] = {
          ...state.msgList[chatType],
          [chatId]: [
            {
              time: parseInt(msg.time),
              type: msg.type,
              msgs: [msg]
            }
          ]
        }
      } else {
        const lastMsgArr = state.msgList[chatType][chatId]
        const lastMsg = lastMsgArr[lastMsgArr.length - 1]
        // 小于5分钟
        if (minsDiff(lastMsg.time, msg.time)) {
          lastMsgArr[lastMsgArr.length - 1].msgs.push(msg)
        } else {
          lastMsgArr.push({
            time: parseInt(msg.time),
            type: msg.type,
            msgs: [msg]
          })
        }
        state.msgList[chatType] = {
          ...state.msgList[chatType],
          [chatId]: lastMsgArr
        }
      }
    },
    [MUTATIONSTYPES.UPDATE_MESSAGE_ID](state, message) {
      if (!message) {
        console.warn('message is undefined')
        return
      }
      const { id, mid } = message
      const msgMap = state.msgList[state.activeUser?.chatType]

      if (msgMap) {
        Object.keys(msgMap).forEach((user) => {
          if (msgMap[user].length) {
            const msgs = msgMap[user]
            const lastGroup = msgs[msgs.length - 1].msgs
            if (lastGroup) {
              lastGroup.some((msg) => {
                if (`${msg.id}` === id) {
                  msg.id = mid
                  state.msgList = Object.assign({}, state.msgList)
                  return true
                }
                return false
              })
            }
          }
        })
      }
    },
    [MUTATIONSTYPES.DELETE_MESSAGE](state, message) {
      const { chatType, to, mid } = message
      const msgs = state.msgList[chatType][to]
      const lastGroup = msgs[msgs.length - 1].msgs

      lastGroup &&
        lastGroup.forEach((msg, index) => {
          if (msg.id === mid) {
            lastGroup.splice(index, 1)
            state.msgList = Object.assign({}, state.msgList)
          }
        })
      console.log('state.msgList', state.msgList)
    },
    [MUTATIONSTYPES.CLEAR_MESSAGE](state) {
      state.msgList = Object.assign(
        {},
        {
          chat: {},
          groupchat: {}
        }
      )
    },
    [MUTATIONSTYPES.UPDATE_AUDIO_STATUS](state, payload) {
      const { activeChatId, messageId, chatType } = payload
      state.msgList[chatType][activeChatId].forEach((item) => {
        item.msgs.forEach((m) => {
          if (m.id === messageId) {
            m.isRead = true
          }
        })
      })
    },
    setAppInitStatus(state, num) {
      // 设置IM初始化状态
      state.appInitStatus = num
    },
    setIMStatus(state, num) {
      // 设置IM要素
      state.IMStatus = num
    },
    setOnlineHelpStatus(state, num) {
      // 设置在线客服要素
      state.onlineHelpStatus = num
    },
    setDownloadAppStatus(state, num) {
      // 设置下载app要素
      state.downloadAppStatus = num
    },
    setAiStatus (state, num) {
      state.showAi = num
    },
    setAiRobotStatus(state, num) {
      state.aiRobotStatus = num
    },
    [MUTATIONSTYPES.SET_USER_INFO](state, val, clearOld) {
      // // 设置用户信息
      if (clearOld === true) {
        return (state.IMuserInfo = val)
      }
      state.IMuserInfo = Object.assign({}, state.IMuserInfo, val)
    },
    [MUTATIONSTYPES.SET_CONTACTLIST](state, val) {
      // 设置联系人列表
      state.contactList = {
        ...state.contactList,
        ...val
      }
    },
    [MUTATIONSTYPES.SET_IMDIALOGVISIBLE](state, val) {
      state.IMDialogVisible = !!val
    },
    [MUTATIONSTYPES.SET_ACTIVEUSER](state, val) {
      // 设置当前已选中的联系人
      state.activeUser = val
    },
    [MUTATIONSTYPES.SET_FULLPAGELOADING](state, val) {
      if (state.fullPageLoading === val) return
      state.fullPageLoading = val
    },
    [MUTATIONSTYPES.SET_UNREADMSGS](state, val) {
      console.log('MUTATIONSTYPES.SET_UNREADMSGS', val)
      state.unreadMsgs = {
        ...val
      }
    },
    [MUTATIONSTYPES.SET_PREVIEW_STATUS](state, val) {
      state.isPreviewing = val
    }
  },
  actions: {
    setAudioRead(context, payload) {
      context.commit(MUTATIONSTYPES.UPDATE_AUDIO_STATUS, payload)
    },
    onGetGroupUserList(context) {
      const options = {
        success(resp) {
          console.debug(
            'im track, conn.getGroup, success',
            JSON.stringify(resp)
          )
          const userList = resp.data
          userList.forEach((user, index) => {
            userList[index].name = user.groupname
          })
          context.commit(MUTATIONSTYPES.UPDATE_USER_LIST, {
            userList,
            type: 'groupUserList'
          })
        },
        error() {
          //
        }
      }
      console.debug('im track, conn.getGroup', JSON.stringify(options))
      IMSDK.conn.getGroup(options)
    },
    onSendText(context, params) {
      // 发送消息
      const { chatType, chatId, to, from, ext = {}, user = {} } = params

      let { message = '' } = params
      const id = IMSDK.conn.getUniqueId()
      const time = new Date().getTime()
      const Msg = new IMSDK.message('txt', id)

      Msg.set({
        msg: message,
        chatType,
        to,
        roomType: false,
        ext,
        success(msgId) {
          if (msgId?.id) {
            msgId = msgId.id
          }
          if (params.msgFrom === 'share') {
            params.success && params.success()
            return
          }
          let yxtMsgBody
          try {
            yxtMsgBody = JSON.parse(ext.yxtMsgBody)
          } catch {
            yxtMsgBody = {}
          }

          let customExts
          // 2-分享
          if ([2].indexOf(ext.yxtMsgType) !== -1) {
            customExts = yxtMsgBody
            // 10-图片
          } else if ([10].indexOf(ext.yxtMsgType) !== -1) {
            customExts = yxtMsgBody
            message = customExts?.image
          } else {
            customExts = yxtMsgBody.content || []
          }
          // debugger
          context.commit(MUTATIONSTYPES.UPDATE_MSG_LIST, {
            chatType,
            chatId,
            msg: message,
            bySelf: true,
            time,
            id: msgId,
            status: 'read',
            type: ext.phimRecall
              ? 'groupNotice'
              : TYPES[ext.yxtMsgType] || 'text',
            to,
            from,
            phimRecall: ext.phimRecall || false,
            customExts,
            user
          })
        },
        fail(e) {
          console.error('Send private text error', e)
          if (e.type === 602) {
            // Vue.$toast.fail(e.reason)
          }
          if (e.type && e.data?.reason) {
            Vue.$message.error(e.data?.reason)
          }
        }
      })
      // hack
      if (chatType === CHAT_TYPE.CHAT_GROUP) {
        // hack 绚信可能不支持 不需要
        Msg.setGroup && Msg.setGroup(CHAT_TYPE.CHAT_GROUP)
      }

      console.debug('im track, conn.send', JSON.stringify(Msg.body))
      IMSDK.conn.send(Msg.body)
    },
    sendImgMessage(context, params) {
      // 发送图片消息
      const {
        chatType,
        chatId,
        roomType,
        file,
        callback,
        to,
        from,
        ext,
        user = {}
      } = params
      const id = IMSDK.conn.getUniqueId()
      const msgObj = new IMSDK.message('img', id)

      msgObj.set({
        apiUrl: IMSDK.config.apiUrl,
        file,
        to: chatId,
        ext,
        roomType,
        onFileUploadError(error) {
          console.error('图片上传失败', error)
          callback()
        },
        onFileUploadComplete(data) {
          const url = data.uri + '/' + data.entities[0].uuid

          context.commit(MUTATIONSTYPES.UPDATE_MSG_LIST, {
            msg: url,
            chatType,
            chatId,
            bySelf: true,
            type: 'img',
            time: data.timestamp,
            id,
            status: 'unread',
            to,
            from,
            user
          })
          callback()
        },
        success() {
          console.log('图片发送成功')
        }
      })
      if (chatType === CHAT_TYPE.CHAT_GROUP) {
        msgObj.setGroup('groupchat')
      }
      // debugger
      IMSDK.conn.send(msgObj.body)
    },
    getHistoryMessage(context, params) {
      return IMSDK.conn.fetchHistoryMessages(setHsitoryMsgOpts(context, params))
    },
    recallMessage(context, params) {
      const { chatType, id: mid } = params.message
      const to = params.to
      const option = {
        mid,
        to,
        type: chatType,
        success(val) {
          console.debug('im track, conn.recallMessage success', val)
          context.commit(MUTATIONSTYPES.DELETE_MESSAGE, { mid, to, chatType })
        },
        fail(e) {
          console.log(e, '消息撤回失败')
        }
      }

      console.debug('im track, conn.recallMessage', JSON.stringify(params))
      IMSDK.conn.recallMessage(option)
    },
    async initAppData({ commit, state, dispatch }) {
      const token = getUserToken()
      if (!token) {
        return console.log('无登录状态')
      }
      if (__imInitTimer__) {
        // 这里判断作用是防止多次调用initAppData方法进行初始化操作
        return __imInitTimer__
      } else if (state.appInitStatus === 1) {
        return {
          IMStatus: state.IMStatus,
          onlineHelpStatus: state.onlineHelpStatus
        }
      }
      const handleCallback = async (resolve, reject) => {
        try {
          const ret = await commonUtil?.preCheckFunctions(
            Object.values(enmuFunctions)
          )
          if (!ret) {
            console.warn('没有获取到要素配置, 停止IM实例化')
            return
          }
          /**
           * 检查对应的功能点的状态(处理已过期的状态)
           * @param {*} point
           * @returns
           */
          // 获取IM消息、下载app、在线客服的要素信息
          let IMStatus = ret[enmuFunctions.IM_CHAT]?.webState
          const zy_IM = ret[enmuFunctions.ZY_IM_CHAT]?.webState
          const hx_IM = ret[enmuFunctions.HX_IM_CHAT]?.webState
          const onlineHelpStatus = ret[enmuFunctions.ONLINE_SERVICE]?.webState
          const downloadAppStatus = ret[enmuFunctions.DOWNLOAD_APP]?.webState
          const showAi = ret[enmuFunctions.AI_CHAT]?.webState
          const aiRobotStatus = ret[enmuFunctions.AI_BOB]?.webState
          if (isDingDing() || IMStatus === 2) {
            // 这里在钉钉环境或者开启了IM消息要素的情况下才走下面逻辑
            if (zy_IM !== 2 && hx_IM !== 2) {
              // 开启了IM能力，但没有开环信或者自研的IM要素，直接返回
              IMStatus = 0
              commit('setAppInitStatus', 1)
              commit('setIMStatus', IMStatus)
              commit('setOnlineHelpStatus', onlineHelpStatus)
              commit('setDownloadAppStatus', downloadAppStatus)
              resolve({
                IMStatus,
                onlineHelpStatus,
                downloadAppStatus
              })
              return
            }
            const { xmppUrl, apiUrl } = domain // 获取环信的webscoket、api地址
            const initAsync = async () => {
              await IMSDK.init({
                easemobParams: { url: xmppUrl, apiUrl }
              })
              // 获取当前IM用户信息
              const name = IMSDK.config.user
              const user = await ImApiUtil.getUserByImName({
                name
              })
              // hack
              Object.assign(user, {
                imUserName: name,
                contacterId: name
              })
              // 保留旧逻辑
              sessionStorage.setItem(
                'IMuserInfo',
                JSON.stringify({
                  imUserName: name,
                  contacterId: name,
                  fullName: user.fullName
                })
              )
              commit(MUTATIONSTYPES.SET_USER_INFO, user)
            }
            dispatch('updateUserList')
            await Promise.all([initAsync(), sUtils.initLang()])
            IMStatus = 2 // 这里重新赋值的目的是当在钉钉环境中，IMStatus可能不为2，因此这里直接设置为2
          }
          commit('setAppInitStatus', 1)
          commit('setIMStatus', IMStatus)
          commit('setOnlineHelpStatus', onlineHelpStatus)
          commit('setDownloadAppStatus', downloadAppStatus)
          commit('setAiStatus', showAi)
          commit('setAiRobotStatus', aiRobotStatus)
          __imInitTimer__ = null
          const result = {
            IMStatus,
            onlineHelpStatus,
            downloadAppStatus
          }
          resolve(result)
        } catch (e) {
          console.error('IM 初始化失败', e)
          __imInitTimer__ = null
          reject(e)
        }
      }
      __imInitTimer__ = new Promise(handleCallback)
      return __imInitTimer__
    },
    async initLang() {
      await sUtils.initLang()
    },
    async updateUserList({ commit, state, dispatch }) {
      let userList = state.userList?.contactUserList
      try {
        commit(MUTATIONSTYPES.SET_CONTACTLIST, { loading: true })
        userList = await getContactsLists(true)
        commit(MUTATIONSTYPES.SET_CONTACTLIST, {
          value: userList,
          loading: false
        })
        if (state.activeUser) {
          const { chatType, contacterId } = state.activeUser
          const from = state.IMuserInfo.contacterId
          dispatch('getHistoryMessage', {
            isGroup: chatType === CHAT_TYPE.CHAT_GROUP,
            chatId: contacterId,
            from
          })
        }
      } catch (e) {
        commit(MUTATIONSTYPES.SET_CONTACTLIST, {
          loading: false
        })
        throw e
      }

      return userList
    },
    async getUserList({ commit, state }) {
      let userList = state.userList?.contactUserList

      if (userList !== null) {
        return userList
      }
      userList = await getContactsLists(true)

      console.debug('userList3', userList)
      commit(MUTATIONSTYPES.SET_CONTACTLIST, {
        value: userList
      })
      return userList
    },
    showIMDialogVisible({ commit, state }, val) {
      if (state.IMDialogVisible === val) return

      commit(MUTATIONSTYPES.SET_IMDIALOGVISIBLE, val)
    },
    /**
     * activeChatId 不传默认取联系人列表第一个
     * @param {*} param0
     * @param {*} activeChatId
     * @returns
     */
    async setContactActiveUser(
      { commit, state, getters, dispatch },
      activeChatId
    ) {
      const oldId = state.activeUser?.contacterId
      console.log('--- - --- - --oldId', oldId)
      if (activeChatId && oldId === activeChatId) return
      if (!activeChatId && oldId) return
      const contactList = state.contactList.value
      let activeUser
      if (!activeChatId) {
        await dispatch('getUserList')
        activeUser = getters.getChatContactList[0]
        if (!activeUser) return
      } else {
        activeUser = contactList.find(
          (user) => user.contacterId === activeChatId
        )
        // console.debug('activeUser', JSON.stringify(activeUser))
        if (!activeUser) {
          try {
            commit(MUTATIONSTYPES.SET_FULLPAGELOADING, true)
            // 加入会话
            await addContactUser({ contacterId: activeChatId })
            activeUser = await ImApiUtil.getUserByImName({ name: activeChatId })
            if (!activeUser) {
              throw new Error('创建会话失败, 没有获取到用户信息')
            }
            activeUser.contacterId = activeChatId
            activeUser.contacterType = 2
            await dispatch('updateUserList')
          } finally {
            commit(MUTATIONSTYPES.SET_FULLPAGELOADING, false)
          }
        }
      }

      if (!activeUser) {
        console.error('没有会话联系人可以选中')
        return
      }

      // hack
      if (!activeUser.hxUserName) {
        activeUser.hxUserName = activeUser.contacterId
      }
      if (!activeUser.chatType) {
        activeUser.chatType = CHAT_TYPE_BY_CONTACTTYPE[activeUser.contacterType]
      }
      commit(MUTATIONSTYPES.SET_ACTIVEUSER, activeUser)
    },
    updateUnreadMsg: debounce(async ({ commit }) => {
      commit(MUTATIONSTYPES.SET_UNREADMSGS, {
        loading: true,
        value: []
      })
      const datas = await getUnreadMsgIdList({ msgType: 0 })
      commit(MUTATIONSTYPES.SET_UNREADMSGS, {
        loading: false,
        value: datas.map((it) => {
          if (it.payload) {
            it.payload = parseMsgPayload(it.payload)
          } else {
            if (it.ext && typeof it.ext === 'string') {
              it.payload = { ext: parseMsgPayload(it.ext) }
              it.payload.bodies = it.bodies
            }
          }
          return it
        })
      })
    })
  },
  getters: {
    IMfetchHistoryMessages(state) {
      return state.msgList
    },
    getIMStatus(state) {
      return state.IMStatus
    },
    getOnlineHelpStatus(state) {
      return state.onlineHelpStatus
    },
    getDownloadAppStatus(state) {
      return state.downloadAppStatus
    },
    getAiChatStatus (state) {
      return state.showAi
    },
    getAiRobotStatus(state) {
      return state.aiRobotStatus
    },
    userInfo(state) {
      return state.IMuserInfo
    },
    isAppDomShow(state) {
      return state.appInitStatus >= 1
    },
    isLoadingContact(state) {
      return state.contactList.loading
    },
    /**
     *
     * @param {*} state
     * @returns
     */
    getContactList(state) {
      return state.contactList.value
    },
    /**
     * 获取系统用户列表。contacterType 1-系统内置用户,2-普通用户,3-群组
     * @param {*} state
     * @returns
     */
    getSysContactList(state) {
      return state.contactList.value.filter((val) => val.contacterType === 1)
    },
    /**
     * 获取单聊，群聊用户列表。contacterType 1-系统内置用户,2-普通用户,3-群组
     * @param {*} state
     * @returns
     */
    getChatContactList(state) {
      return state.contactList.value.filter(
        (val) => val.contacterType === 2 || val.contacterType === 3
      )
    },
    /**
     *
     * @param {*} state
     * @returns
     */
    selectedContact(state) {
      return state.activeUser
    },
    getContactUnreadCount(state, getters) {
      const contacters = getters.getChatContactList
      const count = contacters.reduce((cur, item) => {
        return cur + item.unread
      }, 0)

      return count > 999 ? '999+' : count
    },
    IMDialogVisible(state) {
      return state.IMDialogVisible
    },
    IMDialogPadding(state) {
      return state.fullPageLoading
    },
    unreadMsgsNotice(state) {
      return state.unreadMsgs.value.filter((it) => {
        return (
          it.payload?.ext?.yxtMsgType === 3 &&
          it.payload?.ext?.yxtMsgBody?.displayPosition === 0
        )
      })
    },
    unreadMsgsNoticePop(state) {
      const msgList = state.unreadMsgs.value.filter((it) => {
        return (
          it.payload?.ext?.yxtMsgType === 3 &&
          it.payload?.ext?.yxtMsgBody?.displayPosition === 1
        )
      })
      console.log(msgList, 'msgListmsgListmsgListmsgListmsgList')
      return msgList[0]
    },
    unreadMsgsSys(state) {
      return state.unreadMsgs.value
    },
    unreadMsgsUrge(state) {
      console.debug('unreadMsgs', state.unreadMsgs)
      return state.unreadMsgs.value.filter(
        (it) => it.payload?.ext?.yxtMsgType === 1
      )
    },
    isPreviewing(state) {
      return state.isPreviewing
    }
  }
}

export default Chat
