<template>
  <section class="im-single-an-item">
    <header>
      <yxtf-divider><span class="im-time-span">{{ node.date | formatNoticeCenterTime }}</span></yxtf-divider>
    </header>
    <article>
      <div class="im-an-img" v-if="node.image">
        <img :src="node.image" />
      </div>
      <div class="im-an-content">
        <h3 >
          <span class="title">{{ node.subject }}</span>
          <yxt-tag v-if="showImportantTag(node.isImportant)" type="warning" size="mini">重要</yxt-tag>
        </h3>
        <div class="im-an-summary" v-if="node.content">{{ node.content }}</div>
        <div class="im-single-an-footer">
          <yxtf-link class='im-ann-link' @click="linkHandler" type="primary" :underline="false"
            >{{$t('h5_IM_click_view_detail')}}</yxtf-link
          >
        </div>
      </div>
    </article>
  </section>
</template>

<script>
// import FrontLink from 'yxt-pc/lib/front-link'
// import FrontDivider from 'yxt-pc/lib/front-divider'
// import FrontLink from '@yxt-pc/foreground/lib/conponents/link'
// import FrontDivider from '@yxt-pc/foreground/lib/conponents/divider'
import { formatNoticeCenterTime } from '../../../filters/filters'
export default {
  components: {
    // yxtfLink: FrontLink,
    // yxtfDivider: FrontDivider
  },
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    node () {
      return this.item.customExts[0]
    },
    isAssist5029 () {
      return this.$route.params?.id === 'assist__50__29'
    }
  },
  filters: {
    formatNoticeCenterTime
  },
  methods: {
    linkHandler () {
      this.$emit('go-link', this.node)
    },
    showImportantTag (v) {
      return (
        v === 1 && this.$route.params?.id === 'assist__50__29'
      )
    }
  }
}
</script>
