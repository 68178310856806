import dayjs from 'dayjs'

export function formatTime (time) {
  const nowStr = new Date().getTime()
  const nowTime = dayjs(nowStr)
  const msgTime = dayjs(time)

  const min = nowTime.diff(msgTime, 'minute')
  if (min === 0) {
    return ''
  } else if (min < 60) {
    return `${min === 0 ? 1 : min}${$t('h5_IM_before_minutes')}`
  }

  const hour = nowTime.diff(msgTime, 'hour')
  if (hour < 24) {
    return `${hour}${$t('h5_IM_before_hours')}`
  } else {
    return msgTime.format('YYYY-MM-DD HH:MM')
  }
}

export function formatNoticeTime (time) {
  const ptime = dayjs(time).valueOf()

  const twentyFourHours = 24 * 60 * 60 * 1000
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const today = `${year}/${month}/${day} 00:00:00`
  const todayTime = new Date(today).getTime()
  const yesterdayTime = new Date(todayTime - twentyFourHours).getTime()

  // return date
  const msgTime = dayjs(time)

  if (ptime >= todayTime) {
    return dayjs(ptime).format('HH:mm')
  } else if (ptime < todayTime && yesterdayTime <= ptime) {
    return `${$t('h5_IM_yesterday')} `
  } else {
    return msgTime.format('YYYY-MM-DD')
  }
}

export function formatNoticeCenterTime (time) {
  if (!time) return ''
  const input = dayjs(time)
  const currentTime = dayjs()

  if (currentTime.year() !== input.year()) {
    return dayjs(time).format('YYYY-MM-DD HH:mm')
  } else if (currentTime.month() === input.month() && currentTime.date() === input.date()) {
    return dayjs(time).format('HH:mm')
  } else {
    return dayjs(time).format('MM-DD HH:mm')
  }
}
