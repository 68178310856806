<template>
  <div
    v-show="!isHiddenIMChat"
    class="im-sidebar__message"
    :class="{ 'is-hide-app-download': isOpenDownloadApp }"
    @click.stop.prevent="showChatWindow"
  >
    <img
      class="default"
      :src="`${staticBaseUrl}assets/148cf22d/f4bcb88d/message.svg`"
    />
    <img
      class="hover"
      :src="`${staticBaseUrl}assets/148cf22d/60070239/hovermsg.svg`"
    />
    <div class="message__unread">
      <p v-if="count">{{ count }}{{ $t('pc_IM_bell_unread_num') }}</p>
      <p v-else>{{ $t('pc_IM_btn_view_msg') }}</p>
    </div>
    <div v-if="getContactUnreadCount" class="point"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import globalMinxins from '@IM/mixins'

import EventBus from '../../utils/eventBus'
import { staticBaseUrl } from '@/conost/index'

export default {
  mixins: [globalMinxins],
  props: {},
  data() {
    return {
      staticBaseUrl,
      count: 0,
      contacterId: ''
    }
  },
  computed: {
    ...mapGetters(['getContactUnreadCount'])
  },
  methods: {
    showChatWindow() {
      EventBus.$emit('IM:showChat')
    }
  }
}
</script>
