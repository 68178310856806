export default {
  // 撤回
  RECALL: 'recall',
  // 发送
  SENT: 'sent',
  // 已读
  READ: 'read',
  // 未读
  UNREAD: 'unread'
}
