<template>
  <div class="im-sidebar" :style="{zIndex: zIndex}">
    <div class="im-sidebar__top">
      <AIRobotAssistant v-if="openAiRobotByApi" @changeIndex="changeIndex"/>
      <div v-if="isShowAppIcon" class="im-sidebar__app">
        <img
          class="default"
          :src="`${staticBaseUrl}assets/148cf22d/99b87e21/app.svg`"
        />
        <p class="hover">{{ $t('pc_IM_download_app') }}</p>
        <div class="app__qrcode">
          <div class="qrcode-inner">
            <p>{{ $t('pc_IM_scan_download_app') }}</p>
            <div v-if="appInfoInited && imgUrl" class="qr">
              <!-- 中欧默认app二维码 -->
              <img
                v-if="!openCustom && (hasCustomCode || isZhongou)"
                :src="imgUrl"
              />
              <yxtbiz-qrcode
                v-else
                :logo-size="0.3"
                :logo="appLogoUrl"
                :size="94"
                :padding="6"
                :hide-link="true"
                :hide-download="true"
                :url="appDownloadPage"
              >
              </yxtbiz-qrcode>
            </div>
          </div>
        </div>
      </div>
      <smart-customer-service
        v-if="isOpenOnlineHelp && !disabledOnlineHelp"
      ></smart-customer-service>
      <ai-robot v-if="openAiChat"></ai-robot>
      <Message v-if="isOpenIM"></Message>
    </div>
    <Backup></Backup>
  </div>
</template>
<script>
import globalMinxins from '@IM/mixins'
import { isCeibs, isISV } from '@IM/utils'
import { staticBaseUrl } from '@IM/conost'
// import { domain } from '@IM/utils/getConfig'
import {
  getOrgpParameter,
  getOrgAppInfo,
  getDingH5Domain,
  getAiIconStatus
} from '@IM/service/main.service'

import Backup from './Backup.vue'
import Message from './Message.vue'
import SmartCustomerService from './SmartCustomerService'
import AiRobot from './AiRobot.vue'
import AIRobotAssistant from './AIRobotAssistant.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  components: {
    Backup,
    Message,
    SmartCustomerService,
    AiRobot,
    AIRobotAssistant
  },
  mixins: [globalMinxins],
  props: {
    disabledOnlineHelp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      staticBaseUrl,
      show: true,
      imgUrl: '',
      openCustom: 0, // 是否开启app定制
      isZhongou: false, // 是否是中欧机构
      appInfoInited: false,
      hasCustomCode: false,
      h5DomainName: '',
      openAiRobotByApi: false,
      zIndex: 899
    }
  },
  computed: {
    ...mapGetters([
      'getAiRobotStatus',
    ]),
    appLogoUrl() {
      if (window.feConfig.apiEnv === 'bfsgprod')
        return `${staticBaseUrl}assets/52287e6b/38c607e0/singapore_appicon.png`
      return this.openCustom
        ? ''
        : `${staticBaseUrl}ufd/2338a3/core/pc/img/logoapp.png`
    },
    // app下载页地址（二维码跳转地址）统一域名 m-phx.yunxuetang.....
    appDownloadPage() {
      return (
        this.h5DomainName + '#/appdownload?og=' + localStorage.getItem('orgId')
      ) // 统一下载页地址
    },
    isShowAppIcon() {
      return !isISV() && this.isOpenDownloadApp
    }
  },
  async created() {
    await this.getOrgH5Domain()
    if (this.getAiRobotStatus === 2) {
      getAiIconStatus().then((res) => {
        this.openAiRobotByApi = res.access
      })
    }
    if (!this.isOpenDownloadApp) return
    await getOrgAppInfo(localStorage.getItem('orgId'), 0).then((res) => {
      this.openCustom = res.openCustom
      this.appInfoInited = true
    })
    let url = window.im_app_qr
    const ceibsUrl = `${staticBaseUrl}ufd/3f5568/im/pc/img/ceibs.png`

    if (isCeibs()) {
      this.isZhongou = true
      url = ceibsUrl
    }

    const res = await getOrgpParameter()
    if (res && res.value) {
      if (res.value === '0') {
        this.imgUrl = url
      } else {
        this.hasCustomCode = true
        this.imgUrl = res.value
      }
    } else {
      this.imgUrl = url
    }
  },
  beforeDestroy() {
    // 删除督促弹窗 hack
    const urgeToast = document.getElementsByClassName('urge-toast', 'div')
    if (urgeToast.length) {
      Array.prototype.forEach.call(urgeToast, (item) => {
        item.remove()
      })
    }
  },
  methods: {
    changeIndex(index) {
      this.zIndex = index
    },
    async getOrgH5Domain() {
      try {
        const res = await getDingH5Domain()
        this.h5DomainName = res.url
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
