<template>
  <announce-items :items="data" @click-handler="clickHandler" />
</template>

<script>
import AnnounceItems from './components/AnnounceItems.vue'
export default {
  components: {
    AnnounceItems
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    clickHandler(item) {
      window.open(item.lintTarget)
    },
    handleCurrentChange(page) {
      this.currentPage = page
    }
  }
}
</script>
