<template>
  <msg-detail :chat-id="chatId" />
</template>

<script>
import MsgDetail from '../msgCenter/MsgDetailList.vue'
export default {
  name: 'YxtImNotices',
  components: { MsgDetail },
  computed: {
    chatId() {
      return this.$route.params.chatId
    }
  }
}
</script>
