function getUrl() {
  let apiUrl =
    (window.location.protocol === 'https:' ? 'https:' : 'http:') +
    '//a1.easemob.com'
  let xmppUrl = '//im-api-v2.easemob.com/ws'
  if (
    window.location.href.indexOf('www.test.com') !== -1 ||
    window.location.href.indexOf('172.17.1.95') !== -1 ||
    window.location.href.indexOf('localhost') !== -1
  ) {
    apiUrl =
      (window.location.protocol === 'https:' ? 'https:' : 'http:') +
      '//a1.easemob.com'
    xmppUrl =
      (window.location.protocol === 'https:' ? 'https:' : 'http:') +
      '//im-api-v2.easemob.com/ws'
  }

  return {
    apiUrl,
    xmppUrl
  }
}

export const configBaseIm = {
  /*
   * XMPP server
   */
  url: getUrl().xmppUrl,
  /*
   * Backend REST API URL
   */
  apiURL: getUrl().apiUrl,
  /*
   * Application Host
   */
  Host: 'easemob.com',
  /*
   * Whether to use HTTPS
   * @parameter {Boolean} true or false
   */
  https: true,
  isHttpDNS: true, // 环信切腾讯云 兼容修改
  /*
   * isMultiLoginSessions
   * true: A visitor can sign in to multiple webpages and receive messages at all the webpages.
   * false: A visitor can sign in to only one webpage and receive messages at the webpage.
   */
  isMultiLoginSessions: true,
  /**
   * Whether to use window.doQuery()
   * @parameter {Boolean} true or false
   */
  isWindowSDK: false,
  /**
   * isSandBox=true:  xmppURL: 'im-api.sandbox.easemob.com',  apiURL: '//a1.sdb.easemob.com',
   * isSandBox=false: xmppURL: 'im-api.easemob.com',          apiURL: '//a1.easemob.com',
   * @parameter {Boolean} true or false
   */
  isSandBox: false,
  /**
   * Whether to console.log in strophe.log()
   * @parameter {Boolean} true or false
   */
  isDebug: window.__DEV__,
  /**
   * Whether to show logs in strophe
   * @parameter {Boolean} true or false
   */
  isStropheLog: false,
  /**
   * will auto connect the xmpp server autoReconnectNumMax times in background when client is offline.
   * won't auto connect if autoReconnectNumMax=0.
   */
  autoReconnectNumMax: 5,
  /**
   * the interval secons between each atuo reconnectting.
   * works only if autoReconnectMaxNum >= 2.
   */
  autoReconnectInterval: 2,
  /**
   * webrtc supports WebKit and https only
   */
  isWebRTC: true, // window.RTCPeerConnection && /^https\:$/.test(window.location.protocol),
  /**
   *  cn: chinese
   *  us: english
   */
  i18n: 'us',
  /*
   * Set to auto sign-in
   */
  isAutoLogin: true,
  /**
   * Size of message cache for person to person
   */
  p2pMessageCacheSize: 500,
  /**
   * When a message arrived, the receiver send an ack message to the
   * sender, in order to tell the sender the message has delivered.
   * See call back function onReceivedMessage
   */
  delivery: true,
  /**
   * Size of message cache for group chating like group, chatroom etc
   */
  groupMessageCacheSize: 200,
  /**
   * 5 actual logging methods, ordered and available:
   * 'TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR'
   */

  loglevel: 'ERROR',

  /**
   * enable localstorage for history messages
   */
  enableLocalStorage: true
}
// export function initConfig ({
//   // IMKEY,
//   xmppUrl,
//   apiUrl,
//   commonDomainH5
// }) {
// config.appkey = IMKEY
// }
