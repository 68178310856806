<template>
  <transition name="el-fade-in">
    <div class="im-sidebar__bottom" v-if="visible" @click="handleClick">
      <div class="backup">
        <img :src="`${staticBaseUrl}assets/148cf22d/57d16366/bottom.svg`" />
      </div>
    </div>
  </transition>
</template>

<script>
import { staticBaseUrl } from '@/conost/index'

const cubic = (value) => Math.pow(value, 3)
const easeInOutCubic = (value) =>
  value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2

export default {
  name: 'ElBacktop',

  props: {
    visibilityHeight: {
      type: Number,
      default: 200
    },
    right: {
      type: Number,
      default: 40
    },
    bottom: {
      type: Number,
      default: 40
    },
    scroller: {
      type: String,
      default: '.yxtbiz-nav-main--front'
    }
  },

  data () {
    return {
      staticBaseUrl,
      el: null,
      container: null,
      visible: false
    }
  },

  mounted () {
    this.throttledScrollHandler = this.onScroll
  },

  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        this.removeEvent()
        this.$nextTick(() => {
          this.getContainer()
          this.bindEvent()
        })
      }
    }
  },
  methods: {
    getContainer () {
      let scroller = null
      if (this.scroller) {
        scroller = document.querySelector(this.scroller)
      }

      const overflowY = scroller ? window.getComputedStyle(scroller).overflowY : null
      if (overflowY === 'auto' || overflowY === 'scroll') {
        this.container = scroller
        this.el = scroller
      } else {
        this.container = document
        this.el = document.documentElement
      }
    },
    bindEvent () {
      this.container.addEventListener('scroll', this.throttledScrollHandler)
    },
    removeEvent () {
      if (this.container) {
        this.container.removeEventListener('scroll', this.throttledScrollHandler)
      }
    },
    onScroll () {
      const scrollTop = this.el.scrollTop
      this.visible = scrollTop >= this.visibilityHeight
    },
    handleClick (e) {
      this.scrollToTop()
      this.$emit('click', e)
    },
    scrollToTop () {
      const el = this.el
      const beginTime = Date.now()
      const beginValue = el.scrollTop
      const rAF =
        window.requestAnimationFrame || ((func) => setTimeout(func, 16))
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress))
          rAF(frameFunc)
        } else {
          el.scrollTop = 0
        }
      }
      rAF(frameFunc)
    }
  },

  beforeDestroy () {
    this.removeEvent()
  }
}
</script>
