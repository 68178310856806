<template>
  <ul class="im-announce-items">
    <li v-for="(item, key) in items" :key="key">
      <single-item
        v-if="item.customExts.length === 1"
        :item="item"
        class="im-announce-item"
        @go-link="goLink"
      ></single-item>
      <multi-item
        v-else
        :item="item"
        class="im-announce-item"
        @go-link="goLink"
      ></multi-item>
    </li>
  </ul>
</template>

<script>
import SingleItem from './SingleItem.vue'
import MultiItem from './MultiItem.vue'

export default {
  components: {
    SingleItem,
    MultiItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    goLink(item) {
      this.$emit('click-handler', item)
    }
  }
}
</script>
