/**
 * sourceCode = 105 领带金融 领取金渠道
 * sourceCode = 106 飞书
 */
export const sourceCode = localStorage.getItem('sourceCode')

/**
 * 功能点枚举值的维护
 */
export const enmuFunctions = {
  IM_CHAT: 'IM_chat', // IM能力
  ZY_IM_CHAT: 'self_research_IM', // 自研IM
  HX_IM_CHAT: 'hx_IM', // 环信IM
  ADDRESS_BOOK_ENCRYPTION: 'address_book_encryption', //
  ONLINE_SERVICE: 'online_service', // 在线客服入口
  DOWNLOAD_APP: 'download_app', // 是否展示下载二维码
  AI_CHAT: 'AI_chat', // 是否展示AI客服
  AI_BOB: 'ai_bob' // 是否展示ai问一问助手
}

// 优先从配置获取baseUrl 以原有cdn地址兜底
export const imagesBaseUrl = window.feConfig?.common?.imagesBaseUrl || 'https://images.yxt.com/'
export const staticBaseUrl = window.feConfig?.common?.staticBaseUrl || 'https://stc.yxt.com/'


