<template>
  <div class="im-sidebar__customer" @click="showAi">
    <img
      :src="`${staticBaseUrl}ufd/407a24/pc/svg/ai.svg`"
      style="margin-top: 16px"
    />
  </div>
</template>

<script>
import { staticBaseUrl } from '@IM/conost'
export default {
  data() {
    return {
      box: null,
      staticBaseUrl
    }
  },
  methods: {
    showAi() {
      if (this.box) {
        document.body.removeChild(this.box)
        this.box = null
      } else {
        this.createAi()
      }
    },
    createAi() {
      this.box = this.createBox()
      this.box.appendChild(this.createCloseBut())
      this.createIframe(this.box)
      document.body.appendChild(this.box)
    },
    // 创建div
    createBox() {
      // 创建一个div 悬浮在右下角
      const div = document.createElement('div')
      div.style.position = 'fixed'
      div.style.right = '90px'
      div.style.bottom = '4px'
      div.style.width = '400px'
      div.style.height = '600px'
      div.style.zIndex = '992332'
      div.style.borderRadius = '10px 0 0 0'
      div.style.boxShadow = '0 0 10px 0 rgba(0, 0, 0, 0.2)'
      return div
    },
    // 创建iframe src为ai机器人的地址  https://meet.yxt.com/cs/?module=learning(产线) 并添加到div中
    createIframe(div) {
      const iframe = document.createElement('iframe')
      iframe.src =
        window.feConfig.common?.AiRobot ||
        'https://meet.yxt.com/cs/?module=learning'
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      iframe.style.border = 'none'
      div.appendChild(iframe)
      return iframe
    },
    // 创建一个关闭按钮
    createCloseBut() {
      const div = document.createElement('div')
      div.style.position = 'absolute'
      div.style.right = '0'
      div.style.top = '0'
      div.style.width = '30px'
      div.style.height = '30px'
      div.style.zIndex = '999999'
      div.style.cursor = 'pointer'
      div.style.display = 'flex'
      div.style.justifyContent = 'center'
      div.style.alignItems = 'center'
      div.style.color = '#333'
      div.style.fontSize = '20px'
      div.style.fontWeight = 'bold'
      div.style.lineHeight = '30px'
      div.innerHTML = '×'
      div.onclick = () => {
        document.body.removeChild(this.box)
        this.box = null
      }
      return div
    }
  }
}
</script>
