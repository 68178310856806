import { ImApiUtil } from '@IM/im-sdk/index'
import MUTATIONSTYPES from '@IM/store/chatMutationsTypes'
import { parseMsgPayload } from '@/utils/index'
import { formatMsgs } from '@/utils/formatMsgs'
import { TYPES } from '@IM/conost/chatType'
import noticeList from '@IM/conost/notcieList'

function unique(arr) {
  const res = new Map()
  return arr
    .filter((item) => !res.has(item.from) && res.set(item.from, 1))
    .map((item) => item.from)
}

function setSystemUsersInfo(message) {
  const sysNotcie = noticeList.find((msg) => msg.id === message.from)
  if (!sysNotcie) return {}
  return {
    hxUserName: sysNotcie.id,
    imUserName: sysNotcie.id,
    to: message.to,
    from: message.from,
    time: +new Date(),
    ...sysNotcie
  }
}

export function setHsitoryMsgOpts(context, parmas) {
  const options = {
    queue: parmas.chatId,
    isGroup: parmas.isGroup,
    count: parmas.count || 20, // 每次获取消息条数
    page: parmas.page, // 页码
    success: async (msgs) => {
      parmas.success && parmas.success(msgs)
      const users = unique(msgs)
      let res = {
        datas: []
      }
      if (parmas.chatType !== 'system') {
        res = await ImApiUtil.getUserInfo({
          groupId: parmas.chatId || '',
          users: users.length ? users : [parmas.chatId]
        })
      }

      const messages = []
      if (msgs.length) {
        msgs.forEach((item) => {
          item = parseMsgPayload(item)
          const ext = item.ext
          const time = Number(item.time)
          const bySelf = item.from === parmas.from
          const opt = { msg: item.data }
          if (item.contentsType === 'TEXT') {
            const msgType = ext?.yxtMsgType
            // 语音消息新处理
            if (ext?.yxtMsgVoice === 1) {
              opt.type = 'audio'
              opt.customExts = ext?.yxtMsgBody || {}
            } else if (ext?.yxtMsgVideo === 1) {
              // 视频信息
              opt.type = 'video'
              opt.customExts = ext?.yxtMsgBody || {}
            } else if ([0, 1, 3, 9].indexOf(msgType) !== -1) {
              // 0 普通业务 1 督促  3 公告 9 通知
              // 如果是系统消息
              opt.type = TYPES[ext?.yxtMsgType]
              opt.customExts = ext?.yxtMsgBody?.content || []
              // 分享
            } else if (msgType === 2) {
              opt.type = TYPES[ext?.yxtMsgType]
              opt.customExts = ext?.yxtMsgBody || {}
              // 创建群
            } else if (msgType === 10) {
              const yxtMsgBody = ext?.yxtMsgBody || {}
              opt.msg = yxtMsgBody?.image
              opt.type = TYPES[ext?.yxtMsgType]
              opt.customExts = yxtMsgBody
              // 创建群
            } else if (msgType === 8) {
              opt.msg = ext?.yxtMsgBody.content[0].subject
              opt.type = TYPES[ext?.yxtMsgType]
              opt.customExts = []
            } else if (msgType === 11) {
              // 入群提示
              opt.msg = ext?.yxtMsgBody.content[0].subject || ''
              opt.type = TYPES[ext?.yxtMsgType]
              opt.customExts = []
            } else if (msgType === 12) {
              opt.msg = ext?.yxtMsgBody.content[0] || ''
              opt.type = TYPES[msgType]
              opt.customExts = []
            } else if (ext?.phimRecall) {
              opt.msg = '撤回了一条消息'
              opt.type = 'groupNotice'
              opt.phimRecall = true
              opt.customExts = []
              // 群修改
            } else {
              opt.type = msgType !== undefined ? TYPES[ext?.yxtMsgType] : 'text'
              opt.customExts = []
            }
          } else if (item.contentsType === 'IMAGE') {
            opt.msg = item.url
            opt.type = 'img'
            opt.customExts = {}
          }

          const msg = {
            chatType: item.type || item.message_type,
            bySelf,
            time,
            id: item.id,
            to: item.to,
            from: item.from,
            status: 'unread',
            isRead: false,
            ...opt
          }

          // 如果是系统消息，补充用户信息, 0普通业务  3公告
          if (
            item.contentsType === 'TEXT' &&
            [0, 3].indexOf(ext?.yxtMsgType) !== -1
          ) {
            msg.user = setSystemUsersInfo(msg)
          } else {
            // hack 环信 绚信
            msg.user = res.datas.find(
              (user) => user.hxUserName === item.from || user.id === item.from
            )
          }

          messages.push(msg)
        })
        let lastMess = formatMsgs(messages)
        lastMess.sort((a, b) => {
          return a.time - b.time
        })
        lastMess.forEach((node) => {
          node.msgs.sort((a, b) => {
            return a.time - b.time
          })
        })
        if (window.sdcs_data_interceptor) {
          // 代表开启了通讯录加密
          lastMess = await window.sdcs_data_interceptor(lastMess)
        }
        context.commit(MUTATIONSTYPES.UPDATE_MSGS_LIST, lastMess)
      }
    },
    fail: function (e) {
      // debugger
      console.error('fail:', e)
      parmas.fail && parmas.fail(e)
    }
  }
  return options
}
