import Http from '../utils/axios'
import IMSDK from '@IM/im-sdk/index'
import { domain } from '@IM/utils/getConfig'

const { IM, global, notice, misc, msg, apiBaseUrl } = domain

export const imHttp = new Http(IM)
export const i18nHttp = new Http(global)
export const noticeHttp = new Http(notice)
export const miscHttp = new Http(misc)
export const msgHttp = new Http(msg)
export const apiBabeHttp = new Http(apiBaseUrl)

// 获取i18n文件
export const getI18nFile = () =>
  i18nHttp.post('langfiles/search', {
    appTypes: ['pc', 'h5'],
    langTags: ['zh', 'en', 'ha'],
    moduleTags: ['im']
  })

export const getFileUrl = (msgId) =>
  imHttp.get(`/messages/${msgId}/fileurl`, {})

// 获取IM用户信息
export const getUserPw = (query) => imHttp.get('/imusers/self', query)
// 搜索用户列表
export const searchUserList = (data) => imHttp.post('/contacts/search', data)

// 获取单个用户信息
export const searchUser = (name) => imHttp.get(`/imusers/${name}`, {})

// 创建一个群主
export const createGroups = (data) => imHttp.post('/imgroups', data)

// 会话更新为已读
export const changeGroupOwer = ({ hxGroupId, newOwnerId }) =>
  imHttp.put(`/imgroups/${hxGroupId}/changeowner`, { newOwnerId })
/**
 * 客户端标记所有非系统会话消息已读
 * @returns
 */
export const changeAllChatMsgStatus = async () => {
  const api = await IMSDK.awaitIMAPI()
  // imHttp.put('contacts/allnonsystemread')
  return api.allnonsystemread()
}

export const deleteGroup = (hxGroupId) =>
  imHttp.delete(`/imgroups/${hxGroupId}`)

export const updateGroupName = ({ hxGroupId, name }) =>
  imHttp.put(`/imgroups/${hxGroupId}`, { name })

export const updateSystemMsgStatus = async (params) => {
  const api = await IMSDK.awaitIMAPI()
  // return imHttp.put('/contacts/allsystemread', {
  //   params
  // })
  return api.updateSystemMsgStatus(params)
}

export const getOrgpParameter = (orgId = '') => {
  const id = localStorage.orgId || orgId
  return miscHttp.get(`orgparsetting/code?code=DownloadQRCode&orgId=${id}`)
}

// 获取群组成员列表
export const getGroupRoleList = (data = {}) =>
  imHttp.get(`/imgroups/${data.hxGroupId}/grouptitles`, data)

// 设置群组成员角色
export const setGroupRole = (data) =>
  imHttp.put(`/imgroups/${data.id}/members/${data.name}/title`, {
    titleId: data.titleId
  })

// 添加群组成员角色
export const addGroupRole = (data) =>
  imHttp.post(`/imgroups/${data.id}/grouptitles`, { title: data.title })

// 编辑群组成员角色
export const udpateGroupRole = (data) =>
  imHttp.put(`/imgroups/${data.id}/grouptitles/${data.titleId}`, {
    title: data.title
  })

// 删除成员角色
export const deleteGroupRole = (data) =>
  imHttp.delete(`/imgroups/${data.id}/grouptitles/${data.titleId}`)

export const deleteGropMembersRole = (id, name) =>
  imHttp.delete(`/imgroups/${id}/members/${name}/title`)

//
export const noticeRead = async (id) => {
  const api = await IMSDK.awaitIMAPI()
  return api.noticeRead(id)
}
// 单条公告已读接口统计
export const noticeGraphicRead = (id) => noticeHttp.post(`/graphic/${id}/read`)

// 公告所有消息标记为已读
export const noticeAllRead = () => noticeHttp.post('/graphic/all/read')

// 获取机构是否开启定制app
export const getOrgAppInfo = (orgId, appType) =>
  miscHttp.get(`/app/${orgId}/downloadinfo?appType=${appType}`)

/**
 * 获取未读消息列表 (0-所有系统消息,1-普通公告,2-催促,3-弹窗公告,其它-待定)
 * @param {*} data
 * @returns
 */
export const getUnreadMsgIdList = async (data) => {
  const api = await IMSDK.awaitIMAPI()
  const { datas } = await api.getUnreadMsgIdList(data)
  return datas
  // return imHttp.get('/messages/unread', {msgType: 0})
}

export const addContactUser = async (params) => {
  const api = await IMSDK.awaitIMAPI()
  return api.addContactUser(params)
  // return imHttp.put(`/contacts/${params.contacterId}/add`, {
  //   params
  // })
}

// 获取未读总数
export const getUnreadMsgCount = async (params = {}) => {
  const api = await IMSDK.awaitIMAPI()
  return api.getUnreadMsgCount(params)
  // imHttp.get('/contacts/unreadcount', params)
}

export const searchunplayed = async (query) => {
  const api = await IMSDK.awaitIMAPI()
  return api.searchUnplayed(query)
  // return imHttp.post('/messages/searchunplayed', query)
}

// 单条消息已读接口统计
export const msgRead = async (msgId, fromUser) => {
  const api = await IMSDK.awaitIMAPI()
  return api.msgRead(msgId, fromUser)
  // imHttp.put(`/messages/${msgId}/read?fromUser=${fromUser}`)
}

// 公告所有消息标记为已读
export const updateSingleChatStatus = async (contacterId) => {
  const api = await IMSDK.awaitIMAPI()
  return api.updateSingleChatStatus(contacterId)
  // imHttp.put(`/contacts/${contacterId}/read`, {})
}
// 所有消息设置为已读
export const changeAllMsgStatus = async () => {
  const api = await IMSDK.awaitIMAPI()
  return api.changeAllMsgStatus()
  // imHttp.put('/contacts/allread')
}

// 置顶指定会话消息
export const putContactsTop = async (contacterId) => {
  const api = await IMSDK.awaitIMAPI()
  return api.putContactsTop(contacterId)
  // imHttp.put(`/contacts/${contacterId}/stick`)
}

// 取消置顶指定会话消息
export const putContactsUnTop = async (contacterId) => {
  const api = await IMSDK.awaitIMAPI()
  return api.putContactsUnTop(contacterId)
  // imHttp.put(`/contacts/${contacterId}/unstick`)
}

export const getContactsLists = async (reSend) => {
  const api = await IMSDK.awaitIMAPI()
  return api.getContactsLists(reSend)
}

// 获取机构h5域名
export const getDingH5Domain = () => {
  return msgHttp.get('ding/h5')
}

export const getAiIconStatus = () => {
  return apiBabeHttp.get('/aiassist/access/check')
}

export const getOrgInfo = (orgId) => {
  return apiBabeHttp.get('/core/org/orgname?orgId=' + orgId)
}