export function getUserToken() {
  return localStorage.getItem('token')
}

const _cachePromises = {}
if (window._cachePromises) {
  Object.assign(_cachePromises, window._cachePromises)
} else {
  window._cachePromises = _cachePromises
}

/**
 * 加载scripts。
 * @param src
 * @param attrs
 * @param opts
 * @returns
 */
export function loadScript(
  src,
  attrs = {
    async: false,
    defer: true
  },
  opts = {
    forceLoad: false,
    allowOnlyOne: false
  }
) {
  if (!src) return
  const loading = _cachePromises[src]
  const hasLoad = [].slice.call(document.scripts).some((d) => d.src === src)

  if (opts.forceLoad !== true) {
    // 若同一脚本 只允许加载一次 且 已有缓存
    if (loading) {
      return loading
    }
    if (hasLoad) {
      return
    }
  }
  _cachePromises[src] = new Promise((resolve, reject) => {
    const callSuccess = () => resolve('success')
    const script = document.createElement('script')
    script.src = src
    script.crossOrigin = 'anonymous'

    if (attrs.async === true) script.async = true
    if (attrs.defer === true) script.defer = true
    script.type = 'text/javascript'
    script.onload = callSuccess
    script.onerror = (e) => {
      document.head.removeChild(script)
      reject(e)
    }
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        callSuccess()
      }
    }
    document.head.appendChild(script)
  }).then(() => {
    delete _cachePromises[src]
  })

  return _cachePromises[src]
}
