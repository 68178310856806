<template>
  <div>
    <div class="im-msg-type">
      <div class="type-left">
        <div class="img">
          <img :src="imgUrl" />
        </div>
        <div class="txt">
          <yxtbiz-user-name :name="name" />
        </div>
      </div>
      <div class="timer">
        {{ formatNoticeTime(timer) }}
      </div>
    </div>
    <div class="msg-subject">{{ subject }}</div>
  </div>
</template>
<script>
import dayjs from 'dayjs'

export default {
  props: {
    content: String,
    imgUrl: String,
    name: String
  },
  data() {
    return {
      subject: '',
      timer: '',
      type: ''
    }
  },
  created() {
    const yxtMsgBody = this.content.ext.yxtMsgBody
    const content = yxtMsgBody.content[0]
    this.subject = content.subject
    this.timer = content.date
  },
  methods: {
    formatNoticeTime(time) {
      const ptime = dayjs(time).valueOf()
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const today = `${year}/${month}/${day} 00:00:00`
      const todayTime = new Date(today).getTime()

      const dateYear = new Date(time).getFullYear()
      // return date
      const msgTime = dayjs(time)

      if (ptime >= todayTime) {
        return dayjs(ptime).format('HH:mm')
      } else if (dateYear < year) {
        return msgTime.format('YYYY-MM-DD HH:mm')
      } else {
        return msgTime.format('MM-DD HH:mm')
      }
    }
  }
}
</script>
