<template>
  <section>
    <yxtf-page-header class="im-page-header" sub-level show-back @back="goBack">
      <template slot="title">
        <yxtbiz-user-name :name="pageTitle" />
      </template>
    </yxtf-page-header>
    <article
      v-if="hasData || !inited"
      v-floading="!inited"
      class="im-detail-container"
    >
      <yxtf-infinite-list
        v-if="inited && !isAnn"
        :finished="finished"
        :loading="loading"
        @load="load"
      >
        <ul v-if="!isAnn && !noData" class="im-notice-detail-list">
          <li v-for="(item, key) in list" :key="key">
            <common-notice-item :data="item" @jump="jump" />
          </li>
        </ul>
      </yxtf-infinite-list>
      <yxtf-infinite-list
        v-else-if="inited"
        :finished="finished"
        :loading="loading"
        @load="load"
      >
        <announcement-list
          class="im-detail-container im-an-body"
          :data="list"
          @jump="jump"
        />
      </yxtf-infinite-list>
    </article>
    <div v-else class="im-empty-container">
      <yxtf-empty :empty-text="$t('pc_IM_bell_no_msg')"></yxtf-empty>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import IMSDK from '@IM/im-sdk/index'
import noticeList from '@IM/conost/notcieList'
import {
  updateSingleChatStatus,
  searchUser,
  noticeAllRead
} from '@IM/service/main.service'
import CommonNoticeItem from '@IM/views/notices/CommonNoticeItem'
import { CHAT_TYPE } from '@IM/conost/chatType'
import AnnouncementList from '@IM/views/announcement/List'

export default {
  name: 'YxtImMsgDetailList',
  components: {
    CommonNoticeItem,
    AnnouncementList
  },
  props: {
    chatId: {
      type: String,
      // required: true
      default: 'assist__50__29'
    }
  },
  data() {
    return {
      list: [],
      inited: false,
      loading: true,
      finished: false,
      pageSize: 20,
      page: 1,
      pageTitle: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    msgList() {
      const msgs =
        this.$store.getters.IMfetchHistoryMessages[CHAT_TYPE.CHAT][
          this.chatId
        ] || []
      return msgs.reduce((pre, cur) => {
        return pre.concat(cur.msgs)
      }, [])
    },
    isAnn() {
      return this.pageType === 'announcement'
    },
    pageType() {
      return noticeList.find((item) => item.id === this.chatId)?.type || ''
    },
    hasData() {
      return this.list.length > 0
    }
  },
  watch: {
    msgList: {
      immediate: true,
      deep: true,
      handler(value) {
        this.list = value instanceof Array ? [].concat(value) : []
        this.list.sort((prev, next) => next.time - prev.time) // 按接收时间倒序排列

        this.$nextTick(() => {
          this.loading = false
        })
        if (this.list.length > 0) {
          this.$nextTick(() => {
            setTimeout(() => {
              updateSingleChatStatus(this.chatId) // 设置全部已读
              noticeAllRead() // 公告所有消息标记为已读
            }, 500)
          })
        }
      }
    },
    chatId() {
      this.load()
    }
  },
  created() {
    this.getHistoryListDebounce = debounce(this.getHistoryList, 600)
  },
  async mounted() {
    console.log('%c MsgDetail组件 ', 'color: #fff;background: blue;')
    await this.initAppData()
    const curUser = await searchUser(this.chatId)
    this.pageTitle = curUser.fullName || ''
    IMSDK.awaitOpen().then(() => {
      this.getHistoryList()
    })
  },
  methods: {
    ...mapActions(['initAppData']),
    goBack() {
      this.$emit('goback')
    },
    load() {
      this.getHistoryListDebounce()
    },
    async getHistoryList() {
      const user = this.userInfo
      if (!user) {
        // eslint-disable-next-line no-debugger
        debugger
        return
      }
      if (this.historyPromise) await this.historyPromise
      this.historyPromise = new Promise((resolve, reject) => {
        this.loading = true
        this.$store.dispatch('getHistoryMessage', {
          chatId: this.chatId,
          isGroup: false,
          from: user.imUserName,
          count: this.pageSize,
          page: this.page,
          chatType: 'system',
          success: (msgs) => {
            console.debug('this.loading msgs', msgs)
            this.inited = true
            // if (IMSDK.type === 'xxim') {
            //   // xxim 目前没做分页，返回的全量数据，这里就不做分页取值了
            //   this.finished = true
            //   this.loading = false
            // }
            if (msgs.length === 0 || msgs.length < this.pageSize) {
              this.finished = true
              this.loading = false
            } else {
              this.page++
            }
            resolve()
          },
          fail: () => {
            this.inited = true
            this.loading = false
            reject(new Error('msg.get.error'))
          }
        })
      })

      return this.historyPromise
    },
    jump(item) {
      this.$emit('jump', item)
    }
  }
}
</script>
