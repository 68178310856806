<template>
  <div class="im-bell">
    <div v-clickoutside="hideBell">
      <div class="im-bell__count" @click.stop.prevent="toggleBell">
        <div v-if="count" class="count">{{ count }}</div>
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1780.000000, -24.000000)">
              <g>
                <g transform="translate(1780.000000, 24.000000)">
                  <g>
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M12,4 C8.13400675,4 5,7.13400675 5,11 L5,19 L19,19 L19,11 C19,7.13400675 15.8659932,4 12,4 Z M12.2168896,6.00461951 C14.8776933,6.11818189 17,8.3112453 17,11 L17,17 L7,17 L7,11 C7,8.23857625 9.23857625,6 12,6 L12.2168896,6.00461951 Z"
                      fill="currentColor"
                      fill-rule="nonzero"
                    ></path>
                    <rect
                      fill="currentColor"
                      x="11"
                      y="2"
                      width="2"
                      height="3"
                      rx="1"
                    ></rect>
                    <rect
                      fill="currentColor"
                      x="3"
                      y="17"
                      width="18"
                      height="2"
                      rx="1"
                    ></rect>
                    <path
                      id="形状结合"
                      d="M12,20 C12.5522847,20 13,19.5522847 13,19 L15,19 L15,19 C15,20.6568542 13.6568542,22 12,22 C10.3431458,22 9,20.6568542 9,19 L11,19 L11,19 C11,19.5522847 11.4477153,20 12,20 Z"
                      fill="currentColor"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <transition v-if="isAppDomShow" name="fade">
        <div v-show="show" class="unread-msg" :class="{ 'im-bell_show': show }">
          <div class="msg-header">
            <div class="unread">
              {{ $t('pc_IM_bell_new_unread_msg').d('最新未读') }}
            </div>
            <div
              class="read"
              :class="{ disabled: disabled }"
              @click="updateSystemMsgStatus"
            >
              {{ $t('pc_IM_bell_set_all_read').d('全部标记已读') }}
            </div>
          </div>
          <yxtf-scrollbar>
            <div v-loading="unreadMsgsSysLoading" class="msg-list">
              <template v-if="count">
                <div
                  v-for="msg in unreadMsgsSys"
                  :key="msg.msgId"
                  class="msg-item-box"
                >
                  <div class="msg-item" @click="handleSingleClick(msg)">
                    <LastContent
                      :content="msg.payload"
                      :img-url="msg.imgUrl"
                      :name="msg.fullName"
                    ></LastContent>
                  </div>
                </div>
              </template>
              <div v-else class="empty">
                <img
                  width="160"
                  :src="`${staticBaseUrl}assets/148cf22d/8dabbc67/empty.svg`"
                  :alt="$t('pc_IM_bell_no_unread_msg')"
                />
                <div class="empty__txt">
                  {{ $t('pc_IM_bell_no_unread_msg') }}
                </div>
              </div>
            </div>
          </yxtf-scrollbar>
          <div class="msg-bottom" @click="handleJump">
            {{ $t('pc_IM_bell_view_all_msg') }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  getUnreadMsgCount,
  noticeAllRead,
  updateSystemMsgStatus,
  getUnreadMsgIdList
} from '@IM/service/main.service'
import { debounce, parseMsgPayload } from '@IM/utils'
import EventBus from '@IM/utils/eventBus'
import LastContent from './lastContent.vue'
import isvMinxins from '@IM/mixins'
import eventMinxins from '@IM/mixins/event'
import { staticBaseUrl } from '@/conost/index'

export default {
  name: 'YxtImBell',
  components: {
    LastContent
  },
  mixins: [isvMinxins, eventMinxins],
  props: {
    env: {
      type: String,
      default: 'dev'
    }
  },
  data() {
    return {
      show: false,
      count: 0,
      staticBaseUrl,
      disabled: false,
      unreadMsgsSys: [],
      unreadMsgsSysLoading: true
    }
  },
  computed: {
    ...mapGetters(['isAppDomShow'])
  },
  created() {
    this.initDataDebounce = debounce(this.getUnreadcount, 600)
    EventBus.$on('IM:getCmdMessage', this.initDataDebounce)
  },
  mounted() {
    this.initDataDebounce()
  },
  methods: {
    ...mapActions(['updateUserList']),
    hideBell() {
      this.show = false
    },
    toggleBell() {
      setTimeout(() => {
        this.show = !this.show
        if (this.show) {
          this.unreadMsgsSys = []
          this.getUnreadMsgIdList()
        }
      }, 100)
    },
    async getUnreadcount() {
      const res = await getUnreadMsgCount({ systemOnly: true })
      console.log('getUnreadcount', res)
      const count = res.data || 0
      if (count === 0) {
        this.disabled = true
      } else {
        this.disabled = false
      }

      this.count = count > 99 ? '99+' : count
    },
    async getUnreadMsgIdList() {
      this.unreadMsgsSysLoading = true
      const res = await getUnreadMsgIdList({ msgType: 0, getAll: true })
      this.unreadMsgsSys =
        res.map((it) => {
          if (it.payload) {
            it.payload = parseMsgPayload(it.payload)
          } else {
            if (it.ext && typeof it.ext === 'string') {
              it.payload = { ext: parseMsgPayload(it.ext) }
              it.payload.bodies = it.bodies
            }
          }
          return it
        }) || []
      this.unreadMsgsSysLoading = false
    },
    updateSystemMsgStatus() {
      const sysIds = this.unreadMsgsSys.map((item) => item.hxUserName)
      this.show = false
      noticeAllRead()
      updateSystemMsgStatus(sysIds)
    },
    handleJump() {
      window.location.href = `${window.location.origin}/#/immsg`
      this.show = false
    },
    handleSingleClick(item) {
      window.location.href = `${window.location.origin}/#/immsg/detail/${item.fromUser}`
      this.show = false
    }
  }
}
</script>
