const api = {}

export const setApiConfig = (imApi) => {
  api.imApi = imApi
}

export function getAreas() {
  return api.imApi.get('regions/tree')
}

// 获取IM用户信息
export function getUserPw(query) {
  return api.imApi.get('/imusers/self', query)
}

// 客户端获取未读消息列表
const getUnreadMsgIdList = (data) => {
  return api.imApi.get('/messages/unread', { params: data })
}
// 客户端查询用户未播放的消息Id
const searchUnplayed = (query) =>
  api.imApi.post('/messages/searchunplayed', query)
// 客户端标记指定消息为已读
const msgRead = (msgId, fromUser) =>
  api.imApi.put(`/messages/${msgId}/read?fromUser=${fromUser}`)
// 客户端获取会话未读消息总数
const getUnreadMsgCount = (params = {}) =>
  api.imApi.get('/contacts/unreadcount', params)
// 客户端标记指定会话消息已读
const updateSingleChatStatus = (contacterId) =>
  api.imApi.put(`/contacts/${contacterId}/read`, {})
// 客户端标记所有会话消息已读
const changeAllMsgStatus = () => api.imApi.put('/contacts/allread')
// 客户端标记系统消息已读
const allnonsystemread = () => api.imApi.put('contacts/allnonsystemread')
// 客户端置顶指定会话消息
const putContactsTop = (contacterId) =>
  api.imApi.put(`/contacts/${contacterId}/stick`)
// 客户端取消置顶指定会话消息
const putContactsUnTop = (contacterId) =>
  api.imApi.put(`/contacts/${contacterId}/unstick`)
// 客户端标记所有系统会话消息已读
const updateSystemMsgStatus = (params) =>
  api.imApi.put('/contacts/allsystemread', params)
// 客户端添加会话联系人
const addContactUser = (params) =>
  api.imApi.put(`/contacts/${params.contacterId}/add`, { params })
// 获取用户导航权限
const getUserInfo = (data) => {
  return api.imApi.post('/imusers/search', data)
}
// 获取系统账号
const getAppusernames = () => api.imApi.get('/appusernames')

export const ImApiUtil = {
  _nameMap: new Map(),
  async getUserInfo(params) {
    const { groupId, users, name } = params
    const query = { groupId }
    if (name) {
      query.hxUserNames = [name]
    } else if (users) {
      query.hxUserNames = users
    }

    const res = await getUserInfo(query)
    return res
  },
  async getUserByImName(params, reset = false) {
    const { groupId, name } = params
    if (!name) {
      console.warn('getUserByImName name is undefined')
      return
    }

    let user, key
    const query = {}
    if (groupId) {
      key = `${groupId}-${name}`
      query.groupId = groupId
    } else {
      key = name
    }
    console.debug('getUserByImName', params, key, this._nameMap)

    user = this._nameMap[key]

    if (reset === false && user) return user

    query.hxUserNames = [name]

    const res = await getUserInfo(query)
    console.debug('getUserByImName res', res)
    user = res?.datas[0]

    this._nameMap[key] = user

    return user
  },
  async getUserList(params) {
    const { groupId, users = [] } = params
    let key = ''
    const userCatch = []

    if (groupId) {
      key = `${groupId}-`
    }
    params.hxUserNames = []
    users.forEach((val) => {
      const user = this._nameMap.get(`${key}${val}`)
      if (user) {
        userCatch.push(user)
      } else {
        params.hxUserNames.push(val)
      }
    })

    const { datas = [] } = await getUserInfo(params)

    datas.forEach((u) => {
      this._nameMap.set(`${key}${u.hxUserName}`, u)
    })
    return userCatch.concat(datas)
  },

  // 获取联系人列表
  _getContactsListsPadding: null,
  _getContactsListsData: null,
  async getContactsLists(useCatch = true) {
    if (this._getContactsListsPadding) await this._getContactsListsPadding

    if (!useCatch) {
      if (this._getContactsListsData) return this._getContactsListsData
    }

    this._getContactsListsPadding = new Promise((resolve, reject) => {
      api.imApi
        .get('/contacts')
        .then((res) => {
          this._getContactsListsData = res?.datas || []
          resolve(this._getContactsListsData)
        })
        .catch(reject)
        .finally(() => {
          this._getContactsListsPadding = null
        })
    })
    return this._getContactsListsPadding
  },
  _getSysUserNamesData: null,
  _getSysUserNamesPadding: null,
  async getSysUserNames(useCatch = true) {
    if (this._getSysUserNamesPadding) await this._getSysUserNamesPadding

    if (useCatch && this._getSysUserNamesData) {
      return this._getSysUserNamesData
    }

    this._getSysUserNamesPadding = new Promise((resolve, reject) => {
      getAppusernames()
        .then((res) => {
          this._getSysUserNamesData = res?.datas || []
          resolve(this._getSysUserNamesData)
        })
        .catch(reject)
        .finally(() => {
          this._getSysUserNamesPadding = null
        })
    })
    return this._getSysUserNamesPadding
  }
}

export const IMsdkApi = {
  // 老接口，环信环境需要使用
  getUnreadMsgIdList,
  searchUnplayed,
  msgRead,
  getUnreadMsgCount,
  updateSingleChatStatus,
  changeAllMsgStatus,
  putContactsTop,
  putContactsUnTop,
  updateSystemMsgStatus,
  addContactUser,
  getContactsLists: ImApiUtil.getContactsLists,
  allnonsystemread,
  noticeRead: async (msgId) => msgRead(msgId, 'assist__50__29')
}

export const transformIMsdkApi = {
  getUnreadMsgIdList(conn) {
    return async (params = {}) => {
      // msgType: 0 是所有系统消息
      // msgType: 1 普通公告
      // msgType: 3 弹框公告
      if (![0, 1, 3].includes(params.msgType)) return { datas: [] }
      const sysUserIds = await ImApiUtil.getSysUserNames()
      let msgIdListParams = ['assist__50__29']
      // 做一下处理，pc端拉取未读系统消息的接口默认只拉取公告消息，因为各页面只会有公告消息需要实时触发，其它消息都是不必要的
      if (params.getAll) {
        // 手动传参数强制拉取所有
        msgIdListParams = []
      }
      const msgs = await conn.getUnreadMsgIdList(msgIdListParams)
      console.log(msgs, 'getUnreadMsgIdList', params)
      const sysMsgs = msgs.filter((item) => sysUserIds.includes(item.from))
      const sysMap = new Map()
      sysMsgs.forEach((it) => {
        sysMap.set(it.from, it)
      })
      const sysData = await ImApiUtil.getUserList({
        users: Array.from(sysMap.keys())
      })
      const newSysData = sysData.map((sys) => {
        const sysMsg = sysMap.get(sys.hxUserName)
        return {
          ...sysMsg,
          ...sys,
          id: sysMsg.id,
          msgId: sysMsg.id
        }
      })
      if (params.msgType === 0) {
        // 系统消息
        return {
          datas: newSysData
        }
      }
      // 下面的代码目前没有啥作用，因为这个接口pc端上目前只有一处调用且参数msgType为0，先注释掉
      // if (params.msgType === 1) {
      //   // 公告消息
      //   const notice = newSysData.filter((msg) => {
      //     const ext = msg.ext ? JSON.parse(msg.ext) : {}
      //     if (ext.yxtMsgType === 3) {
      //       const yxtMsgBody = ext.yxtMsgBody ? JSON.parse(ext.yxtMsgBody) : {}
      //       return yxtMsgBody.displayPosition === 0
      //     }
      //     return false
      //   })
      //   return {
      //     datas: notice
      //   }
      // }
      // if (params.msgType === 3) {
      //   // 弹框公告消息
      //   const popupNotice = newSysData.filter((msg) => {
      //     const ext = msg.ext ? JSON.parse(msg.ext) : {}
      //     if (ext.yxtMsgType === 3) {
      //       const yxtMsgBody = ext.yxtMsgBody ? JSON.parse(ext.yxtMsgBody) : {}
      //       return yxtMsgBody.displayPosition === 1
      //     }
      //     return false
      //   })
      //   return {
      //     datas: popupNotice
      //   }
      // }
    }
  },
  _conChatUserIds: null,
  async _getConChatUserIds(conn) {
    if (this._conChatUserIds === null) {
      const sysUserIds = await ImApiUtil.getSysUserNames()
      const conUsers = await conn.getConvList()
      const usersMap = new Map()
      this._conChatUserIds = []
      conUsers.forEach((it) => {
        usersMap.set(it.contacterId, it)
        if (!sysUserIds.includes(it.contacterId)) {
          this._conChatUserIds.push(it.contacterId)
        }
      })
    }
    return this._conChatUserIds
  },
  getContactsLists(conn) {
    return async () => {
      const sysUserIds = await ImApiUtil.getSysUserNames()
      console.log(sysUserIds, 'sysUserIds')
      const conUsers = await conn.getConvList(sysUserIds)
      const usersMap = new Map()

      conUsers.forEach((it) => {
        usersMap.set(it.contacterId, it)
      })

      const userData = await ImApiUtil.getUserList({
        users: Array.from(usersMap.keys())
      })
      console.debug('getContactsLists conSysUserIds', conUsers, userData)
      this._conChatUserIds = []

      userData.forEach((val) => {
        if (!val.hxUserName) {
          console.error('系统内置账号没有hxUserName')
        }
        const hxUserName = val.hxUserName
        const imUser = usersMap.get(hxUserName)
        if (!imUser) {
          console.error('没有获取到对应IM账号', hxUserName, usersMap)
        }
        let contacterType = imUser.contacterType
        if (sysUserIds.includes(imUser.contacterId)) {
          contacterType = 1
        } else {
          this._conChatUserIds.push(hxUserName)
        }
        Object.assign(val, imUser, { contacterType, fullName: val.fullName })
      })

      // 合并数据
      const newUserData = conUsers.map((item) => {
        const contact = userData.find((v) => v.contacterId === item.contacterId)
        if (contact) {
          // 非系统用户取xxim返回的fullName，因为im后端返回用户名没做更新，导致修改群名称不生效
          let fullName = item.fullName
          if (/^assist__/.test(contact.hxUserName)) {
            // 兼容系统用户名称的国际化，如果是系统用户，是用im后端返回的fullName,因为系统用户im后端这边做的国际化
            fullName = contact.fullName
          }
          return { ...contact, ...item, fullName }
        }
        return item
      })
      userData.forEach((val) => {
        if (!usersMap.has(val.contacterId)) {
          newUserData.push(val)
        }
      })
      return newUserData
    }
  },
  changeAllMsgStatus(conn) {
    return conn.changeAllMsgStatus
  },
  allnonsystemread(conn) {
    return async (contacterIds = []) => {
      return conn.updateMultipleChatStatus(contacterIds)
    }
  },
  getUnreadMsgCount(conn) {
    // 获取未读消息总数
    return async (params = {}) => {
      if (params.systemOnly) {
        // 只获取系统消息未读数量
        const sysUserIds = await ImApiUtil.getSysUserNames()
        return conn.getUnreadMsgCount(sysUserIds)
      }
      return conn.getUnreadMsgCount()
    }
  },
  noticeRead(conn) {
    return async (id) => {
      return conn.msgRead([id], 'assist__50__29', 'signalChat')
    }
  }
}
