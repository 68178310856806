<template>
  <div>
    <div v-if="isShow" class="im-announcement-bar">
      <yxtf-carousel
        class="im-announcement-bar-inner"
        direction="vertical"
        :loop="true"
        arrow="none"
        indicator-position="none"
      >
        <yxtf-carousel-item v-for="(item, key) in list" :key="key">
          <span class="im-announcement-content"
            ><span>{{ $t('h5_IM_noticelist_type_announcement') }}：</span
            ><span class="hand" @click="clickHandler(item)">{{
              item | title
            }}</span></span
          ><yxtf-link
            class="im-announcement-link-span"
            :underline="false"
            type="primary"
            @click="clickHandler(item)"
            >{{ $t('h5_IM_view_more') }}</yxtf-link
          >
        </yxtf-carousel-item>
      </yxtf-carousel>
      <yxtf-svg
        class="im-announcement-close color-gray-6 hover-primary-6"
        icon-class="delete-1"
        width="16px"
        height="16px"
        @click.native="close"
      />
    </div>

    <!-- 公告弹窗 -->
    <notice-dialog />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import IMSDK from '@IM/im-sdk/index'

import EventBus from '@IM/utils/eventBus'
import noticeDialog from './components/noticeDialog'
import isvMinxins from '@IM/mixins'

export default {
  name: 'YxtImAnnouncement',
  components: {
    noticeDialog
  },
  filters: {
    title(input) {
      if (input && input.payload && input.payload.bodies) {
        return input.payload.bodies[0].msg
      }
      return ''
    }
  },
  mixins: [isvMinxins],
  props: {
    showLamp: { type: Boolean, default: true }
  },
  data() {
    return {
      visible: this.showLamp && localStorage.getItem('announcement') !== '0'
    }
  },
  computed: {
    ...mapGetters(['unreadMsgsNotice']),
    list() {
      const datas = this.unreadMsgsNotice
      if (!datas || !datas.length) return []
      const list = datas.splice(0, 10)
      if (list.length === 2) {
        list.push(list[0])
      }
      return list
    },
    isShow() {
      console.debug('isShow', this.showLamp && this.list.length > 0 && this.visible, this.list)
      return this.showLamp && this.list.length > 0 && this.visible
    }
  },
  watch: {
    showLamp: {
      immediate: true,
      handler(val) {
        if (val === true) {
          this.updateUnreadMsgList()
        }
      }
    }
  },
  created() {
    console.debug('showLamp:', this.showLamp)
    EventBus.$on('IM:getCmdMessage', () => {
      if (this.showLamp === false) return
      localStorage.setItem('announcement', '')
    })
  },
  mounted() {
    this.updateUnreadMsgList()
  },
  methods: {
    ...mapActions(['updateUnreadMsg']),
    updateUnreadMsgList() {
      if (!this.showLamp) return
      IMSDK.awaitOpen().then(() => {
        this.updateUnreadMsg()
      })
    },
    clickHandler(item) {
      window.location.href = `${window.location.origin}/#/immsg/detail/${item.fromUser}`
    },
    close() {
      localStorage.setItem('announcement', '0')
      this.visible = false
    }
  }
}
</script>
